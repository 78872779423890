import { FC } from 'react'

const ComingSoonBall: FC = () => {
  return (
    <div className="coming-soon-ball">
      <div />
      <div />
    </div>
  )
}

export default ComingSoonBall
