import './style.css'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { FC, useState } from 'react'

import { Pip } from './Pip'

interface RangeSliderProps {
  // ranges: { value: string; label: string; weight: string }[]
  value: string
  setValue: (value: string) => void
  weights: any
  pools: any
  poolType: string
}

export const RangeSlider: FC<RangeSliderProps> = ({
  // ranges,
  value,
  setValue,
  weights,
  pools,
  poolType,
}) => {
  const getWeight = (poolIndex: string) => {
    const poolWeight = weights.find((item) => item.poolIndex === poolIndex)
    const weight = Number(poolWeight?.weight)
    return weight.toFixed(2)
  }

  const MAX_WEEKS = 52
  const RANGER_STEP = 2

  const [selectedWeek, setSelectedWeek] = useState(String(MAX_WEEKS))
  const getRangerPercentageValue = (value: number) =>
    Number(value) / (weights.length / 100)
  return (
    <Box position="relative" mt="20px">
      {/* <Box position="relative">
        <Box display='flex'>
          <Text color="main.white" variant="overline">
            Don't have UFO tokens? Buy now using Uniswap!
          </Text>

        </Box>
      </Box> */}
      <Box
        className="customRangeBg"
        marginRight="15px"
        background={`linear-gradient(90deg, #9bffe2 0%, #9bffe2 ${getRangerPercentageValue(
          Number(value)
        )}%, #102017 ${
          getRangerPercentageValue(Number(value)) + 0.01
        }%, #102017 100%)`}
      />

      <Box display="flex" justifyContent="space-between">
        <Text color="main.white">Lock for: {selectedWeek} Weeks</Text>
        <Text color="main.white">Weight: {getWeight(selectedWeek)}x</Text>
      </Box>

      <input
        type="range"
        className="rangeSlider"
        min="0"
        max="53"
        step={RANGER_STEP}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          setSelectedWeek(e.target.value)
        }}
      />

      <Flex
        align="center"
        justify="space-between"
        w="100%"
        zIndex="2"
        sx={{ padding: '0 20px' }}
      >
        <Flex
          align="center"
          justify="center"
          flexDir="column"
          position="relative"
          height="120px"
          left={`${(-40 * 33).toString()}px`}
        />
      </Flex>
      <Flex
        mt={25}
        mb={35}
        sx={{ borderBottom: '1px solid #fff', paddingBottom: '20px' }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Heading as="h5" variant="h6" color="main.white" marginRight="6px">
          Estimated {poolType} APR:
        </Heading>
        <Box color="main.white">
          <Box color="#5DFFCF" textAlign="right">
            <Text fontSize="16px" lineHeight="21px" fontWeight="700">
              {Number(
                pools.find((item) => item.lockInWeeks === selectedWeek)?.poolApr
              ).toFixed(2)}
              %
            </Text>
            <Text fontSize="12px" lineHeight="19px" fontWeight="400">
              + Plasma points
            </Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}
