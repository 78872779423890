import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const sentryDsn =
  'https://24d31145b34440da8af041223e54cfa9@o1201338.ingest.sentry.io/6326034'

const breadcrumbsRegExp = new RegExp('xhr|console|fetch', 'i')
const sentryInitOptions: Sentry.BrowserOptions = {
  dsn: sentryDsn,
  integrations: [new BrowserTracing() as any],
  tracesSampleRate: 1.0,
  ignoreErrors: [
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'TypeError: Cancelled',
    'TypeError: cancelado',
  ],
  beforeSend(event) {
    if (event.level === 'error') {
      event.breadcrumbs = event.breadcrumbs?.filter(
        (value: Sentry.Breadcrumb) => {
          return !breadcrumbsRegExp.test(value.category!)
        }
      )
      return event
    }
    return null
  },
}

export const sentryInit = function () {
  Sentry.init(sentryInitOptions)
}
