import { getFormatedDate } from './getFormatedDate'

const intervals = [
  { label: 'day', seconds: 86399 },
  { label: 'hour', seconds: 3600 },
  { label: 'min', seconds: 60 },
]

const constructIntervalString = (
  count: number | undefined,
  label: string
): string => `${count} ${label}${count !== 1 ? 's' : ''} ago`

export const getFormatedDateStringForLeaderBoard = (date: number): string => {
  const seconds = Math.floor((Date.now() - date * 1000) / 1000)
  if (seconds <= 59) {
    return 'Now'
  }

  const interval = intervals.find((i) => i.seconds < seconds)
  const count = interval && Math.floor(seconds / interval.seconds)

  switch (interval?.label) {
    case 'day':
      return getFormatedDate(String(date))
    case 'hour':
    case 'min':
      return constructIntervalString(count, interval.label)
    default:
      return ''
  }
}
