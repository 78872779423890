import { Box, Flex, Heading, Text, useMediaQuery } from '@chakra-ui/react'
import { useRef, FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../store/types'

import { ComingSoon } from '@/components'
import Loader from '@/components/Loader'
import StakesRow from '@/components/Stakes/StakingRow'
import { getStakingLeaderBoards } from '@/services/index'

interface iStakeData {
  depositor: string
  lpApr: string
  myLpShare: string
  myShare: string
  myUfoShare: string
  totalLpStaked: string
  totalLpStakedInDollars: string
  totalUfoStaked: string
  totalUfoStakedInDollars: string
  ufoApr: string
  lastUpdated: string
  amount: string
  depositTimeStamp: string
}

interface iLeaderboardItem {
  depositor: string
  isUfoPool: boolean
  depositTimeStamp: string
  amount: string
  stakedInDollars: string
}
interface iStakesData {
  ufoData: iLeaderboardItem[]
  lpData: iLeaderboardItem[]
}

interface iLeaderboardData {
  recent: iStakesData
  leaderboard: iStakesData
}

interface iStakesPageProps {
  order?: string
}

const StakesPage: FC<iStakesPageProps> = ({ order = 'recent' }) => {
  const { selectedLanguage } = useSelector((state: RootState) => state.language)
  const {
    body: { wallet_address, staked_at, Amount },
  } = selectedLanguage
  const [leaderBoard, setLeaderBoard] = useState<iLeaderboardData | null>(null)
  const [isLargerThan884] = useMediaQuery('(max-width: 987px)')
  const ufoStakingLoader = useRef<any | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      // setDataFetching(true);
      setLeaderBoard(null)
      ufoStakingLoader.current && ufoStakingLoader?.current?.continuousStart()
      const ufoDataLeaderboard = await getStakingLeaderBoards(2, true)
      const lpDataLeaderboard = await getStakingLeaderBoards(2, false)
      const ufoDataRecent = await getStakingLeaderBoards(1, true)
      const lpDataRecent = await getStakingLeaderBoards(1, false)
      const data = {
        recent: {
          ufoData: ufoDataRecent,
          lpData: lpDataRecent,
        },
        leaderboard: {
          ufoData: ufoDataLeaderboard,
          lpData: lpDataLeaderboard,
        },
      }
      setLeaderBoard(data)
    }
    fetchData()
  }, [])

  return (
    <Flex
      flexDirection={isLargerThan884 ? 'column' : 'row'}
      gridGap="15px"
      justifyContent="space-between"
      marginTop="37px"
      position="relative"
      background="rgba(248, 244, 228, 0.8)"
      backdropFilter="blur(10px)"
    >
      {/* {!isLargerThan988 && <ComingSoon top='25px' />} */}
      {leaderBoard ? (
        <>
          <Flex position="relative" direction="column" flex="1">
            <Heading
              variant="h6"
              as="h6"
              color="main.spaceGrey"
              fontSize="18px"
              mb={15}
              width={{ base: '275px', sm: '100%' }}
            >
              UFO Staking
            </Heading>

            <Box display="flex" flexDirection="column" gap="15px">
              {/* {isLargerThan988 && <ComingSoon  />} */}

              <Flex
                display={isLargerThan884 ? 'none' : 'flex'}
                padding="0 30px"
                fontSize="12px"
                fontWeight="400"
                color="main.spaceGrey"
              >
                <Text paddingRight="20px">Rank</Text>
                <Text>Wallet address</Text>
                {/* <Text>Stake at</Text> */}
                <Text marginLeft="auto">Amount, UFO</Text>
              </Flex>
              {leaderBoard &&
                leaderBoard[order]?.ufoData?.map(
                  (
                    { depositor, amount, depositTimeStamp, stakedInDollars },
                    index
                  ) => (
                    <StakesRow
                      key={index}
                      depositor={depositor}
                      totalStakedInDollars={stakedInDollars}
                      myShare={amount}
                      rank={Number(index) + 1}
                      type="ufo"
                      date={depositTimeStamp}
                    />
                  )
                )}
            </Box>
          </Flex>
          <Flex position="relative" direction="column" flex="1">
            <Heading
              variant="h6"
              as="h6"
              color="main.spaceGrey"
              fontSize="18px"
              mb={15}
              width={{ base: '275px', sm: '100%' }}
            >
              UFO-ETH LP Staking
            </Heading>
            <Box display="flex" flexDirection="column" gap="15px">
              {/* {isLargerThan988 && <ComingSoon  />} */}
              <Flex
                display={isLargerThan884 ? 'none' : 'flex'}
                padding="0 30px"
                fontSize="12px"
                fontWeight="400"
                color="main.spaceGrey"
              >
                <Text paddingRight="20px">Rank</Text>
                <Text>Wallet address</Text>
                <Text marginLeft="auto">Amount, UFO-ETH LP</Text>
              </Flex>
              {leaderBoard &&
                leaderBoard[order]?.lpData?.map(
                  (
                    { depositor, amount, depositTimeStamp, stakedInDollars },
                    index
                  ) => (
                    <StakesRow
                      key={index}
                      depositor={depositor}
                      totalStakedInDollars={stakedInDollars}
                      myShare={amount}
                      rank={Number(index) + 1}
                      type="lp"
                      date={depositTimeStamp}
                    />
                  )
                )}
            </Box>
          </Flex>
        </>
      ) : (
        <Loader ref={ufoStakingLoader} />
      )}
    </Flex>
  )
}

export default StakesPage
