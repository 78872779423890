import { RootThunkAction } from '../types'

import {
  getTvlInLpPools,
  getTvlInUfoPools,
} from '@/components/pages/Markets/service'
import { Api } from '@/services'
import {
  CHANGE_LANGUAGE,
  SET_LP_TOTAL_POOL,
  SET_UFO_TOTAL_POOL,
} from '@/store/language/types'

export const getLanguage = (selectedLanguageId: number): RootThunkAction => {
  return async (dispatch) => {
    try {
      const attributes = await Api.GetLanguage(selectedLanguageId)

      dispatch({
        type: CHANGE_LANGUAGE,
        payload: { selectedLanguage: attributes.content },
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}

export const getAllValues = (): RootThunkAction => {
  return async (dispatch) => {
    try {
      const ufoTotal = await getTvlInUfoPools()
      const LpPools = await getTvlInLpPools()

      dispatch({
        type: SET_UFO_TOTAL_POOL,
        payload: ufoTotal,
      })
      dispatch({
        type: SET_LP_TOTAL_POOL,
        payload: LpPools,
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}
