import { Box, Flex, Text } from '@chakra-ui/react'
import { FC, useState } from 'react'

import { PartialUnstakeFuction } from './PartialUnstakeFuction'
import { UnstakeFunction } from './UnstakeFuction'

import { ReactComponent as UfoIcon } from '@/assets/img/UFO_icon.svg'
import { ModalPopup } from '@/components'
import ButtonBase from '@/components/button'
import { CustomSlider } from '@/components/molecules/CustomSlider/Index'
import { useWallet } from '@/web3/WalletContext'

interface IUnstakeData {
  lockWeeks: string
  amount: string
  depositNumber: string
  depositNumbers: string[]
  poolType: string
}

interface iUnstakeModalProps {
  isOpen: boolean
  data: IUnstakeData | null
  onCloseModal: () => void
}

const unstakeModalStyle = {
  label: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '19px',
    marginTop: '20px',
    padding: '0 20px',
  },
  input: {
    border: '1px solid #F8F4E4',
    borderRadius: '50px',
    p: '15px 20px',
    fontSize: '16px',
    textTransform: 'uppercase',
    lineHeight: '1',
  },
  textBold: {
    fontWeight: '800',
    fontSize: '18px',
    lineHeight: '23px',
    letterSpacing: '0.27px',
  },
  textColored: {
    fontSize: '16',
    fontWeight: '400',
    lineHeight: '1',
    color: '#77f1c5',
  },
}
export const UnstakeModal: FC<iUnstakeModalProps> = ({
  isOpen,
  onCloseModal,
  data,
}) => {
  const [rangeValue, setRangeValue] = useState(0)
  const [loading, setLoading] = useState(false)
  const { signer } = useWallet()
  const { textBold, label, textColored, input } = unstakeModalStyle

  const handleCloseWithLoading = () => {
    setLoading(false)
    onCloseModal()
  }

  return (
    <ModalPopup title="Unstake" isOpen={isOpen} onCloseModal={onCloseModal}>
      <Box borderTop="1px solid #fff" color="#fff" pb="16px">
        <Flex direction="column">
          <Text sx={label}>Pool</Text>
          <Flex sx={input}>{data?.poolType?.replace('_POOL', '')}</Flex>
        </Flex>
        {/* <Flex direction="column" mt="20px" pl="20px" pr="20px">
          <Flex width="100%" justifyContent="space-between">
            <Text sx={label}>Pool</Text>
            <Text sx={label}>Balance</Text>
          </Flex>
          <Box>
            <InputGroup
              borderRadius="50px"
              border="1px solid white"
              background="transparent"
              height="50px"
              alignItems="center"
            >
              <Input
                type="number"
                placeholder="Enter amount"
                onChange={(e) => ''}
                value={12}
                borderRadius="50px"
                background="transparent"
                focusBorderColor="none"
                variant="outline"
                border="none"
              />
              <InputRightElement width="4.2" position="relative">
                <Button
                  h="1.75"
                  size="sm"
                  variant="ghost"
                  color="main.purpleHighlight"
                  onClick={() => ''}
                  pt="0"
                  pb="0"
                >
                  MAX
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
        </Flex> */}
        <Flex direction="column" margin="20px" borderBottom="1px solid #fff">
          <CustomSlider
            max={100}
            setValue={setRangeValue}
            min={0}
            value={rangeValue}
          />
          <Flex justifyContent="space-between" pt="10px" pb="10px">
            <Text>{rangeValue}%</Text>
            <Text>100%</Text>
          </Flex>
          <Flex justifyContent="space-between" pt="10px" pb="10px">
            <Text sx={textBold}>
              You Will <br />
              Unstake
            </Text>
            <Flex direction="column" textAlign="right">
              <Text sx={textBold}>
                Total {data?.poolType?.replace('_POOL', '')} amount{' '}
                {data?.amount}
              </Text>
              <Text sx={textColored}>
                {(Number(data?.amount) * rangeValue) / 100}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <ButtonBase
          isFullWidth
          padding="5px 5px 5px 20px"
          color="main.cream"
          h="55px"
          mt="20px"
          disabled={loading}
          onClick={() => {
            setLoading(true)
            if (data?.depositNumber) {
              if (rangeValue === 100) {
                data &&
                  UnstakeFunction(
                    data?.lockWeeks,
                    data?.depositNumber,
                    data?.poolType?.replace('_POOL', ''),
                    signer,
                    handleCloseWithLoading
                  )
              } else {
                data &&
                  PartialUnstakeFuction(
                    data?.lockWeeks,
                    String(rangeValue),
                    data?.poolType?.replace('_POOL', ''),
                    handleCloseWithLoading
                  )
              }
            } else {
              if (rangeValue === 100) {
                data &&
                  UnstakeFunction(
                    data?.lockWeeks,
                    data?.depositNumbers,
                    data?.poolType?.replace('_POOL', ''),
                    signer,
                    handleCloseWithLoading
                  )
              } else {
                data &&
                  PartialUnstakeFuction(
                    data?.lockWeeks,
                    String(rangeValue),
                    data?.poolType?.replace('_POOL', ''),
                    handleCloseWithLoading
                  )
              }
            }
          }}
        >
          Unstake Now
          <UfoIcon width={45} height={45} />
        </ButtonBase>
      </Box>
    </ModalPopup>
  )
}
