import { FC } from 'react'

const PageSmallBall: FC = () => {
  return (
    <div className="small-ball">
      <div />
    </div>
  )
}

export default PageSmallBall
