import { Box, Flex, HStack, Text, useMediaQuery } from '@chakra-ui/react'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-scroll'

import { ROUTES } from '@/Routes'
import { Blip } from '@/components'
import Social from '@/components/social'
import { useWallet } from '@/web3/WalletContext'

type iProps = {
  setLanguage: React.Dispatch<React.SetStateAction<string>>
  onCloseModal: () => void
  openMobileMenu: () => void
}

export const Menu: FC<iProps> = ({
  setLanguage,
  onCloseModal,
  openMobileMenu,
}) => {
  const [isLargerThan768] = useMediaQuery('(max-width: 768px)')

  const { isConnected } = useWallet()

  return (
    <HStack spacing="2">
      {ROUTES.filter((route) => {
        if (route.path == 'leaderboard') return true
        if (isConnected) return route.menu
        return !route.menu
      }).map((route, index) => {
        return (
          <Flex
            key={index}
            px={1}
            alignItems="center"
            justifyContent="center"
            position="relative"
            className="menu-item"
            display={isLargerThan768 ? 'none' : 'flex'}
            cursor="pointer"
          >
            <Link to={route.path}>
              <Text>{route.title.toLocaleUpperCase()}</Text>
            </Link>
          </Flex>
        )
      })}
      <Social
        header
        dark
        setLanguage={setLanguage}
        onCloseModal={onCloseModal}
        openMobileMenu={openMobileMenu}
      />
    </HStack>
  )
}
