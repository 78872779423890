import './style.css'
import { Link, Box, Flex, Text, Heading, useMediaQuery } from '@chakra-ui/react'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { FC, useState, useEffect, ReactNode } from 'react'

import { ROUTES } from '@/Routes'
import { ReactComponent as UfoIcon } from '@/assets/img/UFO_icon.svg'
import { ReactComponent as BurgerMenu } from '@/assets/img/burger_menu.svg'
import { ReactComponent as Cancel } from '@/assets/img/cancel.svg'
import { ReactComponent as LanguageIcon } from '@/assets/img/language_icon.svg'
import { ReactComponent as Logo } from '@/assets/img/logo-white.svg'
import { ReactComponent as Discrod } from '@/assets/img/social/discord.svg'
import { ReactComponent as Language } from '@/assets/img/social/language-icon.svg'
import { ReactComponent as Mul } from '@/assets/img/social/mul.svg'
import { ReactComponent as Telegram } from '@/assets/img/social/telegram.svg'
import { ReactComponent as Twitter } from '@/assets/img/social/twitter.svg'
import { ReactComponent as Youtube } from '@/assets/img/social/youtube.svg'
import ButtonBase from '@/components/button'
import MyWallet from '@/components/molecules/MyWallet'
import { useWallet } from '@/web3/WalletContext'

interface iMenuBurgerComponent {
  isOpen: boolean
  setIsOpen: any
  openVideoModal: () => void
}

const WalletConnectedContent = () => {
  return (
    <>
      <Link href="#stake">
        <Heading>Staking</Heading>
      </Link>
      <Link href="#rewards">
        <Heading>Rewards</Heading>
      </Link>
      <Link href="#leaderboard">
        <Heading>Leaderboard</Heading>
      </Link>
    </>
  )
}

const WalletDisconnectedContent = () => {
  return (
    <>
      <Link href="#how_to_stake">
        <Heading>How to stake</Heading>
      </Link>
      <Link href="#leaderboard">
        <Heading>Leaderboard</Heading>
      </Link>
      <Link href="#faq">
        <Heading>FAQ</Heading>
      </Link>
    </>
  )
}

const BurgerMenuComponent: FC<iMenuBurgerComponent> = (props) => {
  const { isOpen, setIsOpen, openVideoModal } = props
  const { isConnecting, isConnected, disconnect, address } = useWallet()
  const [isSmallerThan480] = useMediaQuery('(max-width: 480px)')
  const handleClick = ({ target }) => {
    if (target.tagName === 'H2') {
      setIsOpen(false)
    }
  }

  return (
    <Flex
      className="burgerMenuComponent"
      display={isSmallerThan480 ? 'flex' : 'none'}
      transform={isOpen ? 'translateY(0)' : 'translateY(-100%)'}
      onClick={handleClick}
    >
      <Flex className="burgerMenuComponent__socials">
        <Box>
          <Link href="/">
            <Logo className="burgerMenuComponent__logo" />
          </Link>
        </Box>
        <Link
          href="https://twitter.com/theufotoken"
          isExternal
          className="link_without_borders"
        >
          <Flex className="burgerMenuComponent__icon">
            <Twitter width="18" height="15" fill="#ffffff" />
          </Flex>
        </Link>

        <Link
          href="https://t.me/ufotokenofficial"
          isExternal
          className="link_without_borders"
        >
          <Flex className="burgerMenuComponent__icon">
            <Telegram className="burgerMenuComponent__svg" />
          </Flex>
        </Link>
        <Link
          href="https://discord.com/invite/Zjdmt62H24"
          isExternal
          className="link_without_borders"
        >
          <Flex className="burgerMenuComponent__icon">
            <Discrod className="burgerMenuComponent__svg" />
          </Flex>
        </Link>
        <Cancel
          className="burgerMenuComponent__cancel-btn"
          onClick={() => setIsOpen(false)}
        />
      </Flex>
      {isConnected ? <WalletConnectedContent /> : <WalletDisconnectedContent />}
      {isConnected ? (
        <MyWallet
          address={address}
          disconnect={disconnect}
          openVideoModal={openVideoModal}
        />
      ) : (
        <ConnectButton.Custom>
          {({
            account,
            chain,
            openAccountModal,
            openChainModal,
            openConnectModal,
            authenticationStatus,
            mounted,
          }) => {
            return (
              <ButtonBase
                size="sm"
                background=" linear-gradient(180deg, #FA937D 0%, #8924F4 100%);"
                color="white"
                disabled={isConnecting}
                onClick={() => !isConnected && openConnectModal()}
              >
                {isConnecting
                  ? 'Connecting...'
                  : isConnected
                  ? 'Connected'
                  : 'Connect Wallet'}
                <UfoIcon width={29} height={29} />
              </ButtonBase>
            )
          }}
        </ConnectButton.Custom>
      )}
    </Flex>
  )
}
export default BurgerMenuComponent
