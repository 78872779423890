import { Center, Text, Box, Heading } from '@chakra-ui/react'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../store/types'

import ComingSoonBall from '@/components/pageBall/ComingSoonBall'

interface iProps {
  top?: string
}

export const ComingSoon: FC<iProps> = (props) => {
  const { selectedLanguage } = useSelector((state: RootState) => state.language)
  const {
    body: { coming_soon },
  } = selectedLanguage
  const { top } = props
  return (
    <Box
      position="absolute"
      left="0"
      top={top}
      width="100%"
      height="95%"
      zIndex="10"
      background="rgba(248, 244, 228, 0.8)"
      backdropFilter="blur(4px)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Center position="relative">
        <ComingSoonBall />
        <Heading textAlign="center" position="absolute" variant="h6" as="h6">
          {coming_soon}
        </Heading>
      </Center>
    </Box>
  )
}
