import {
  Link,
  Flex,
  Box,
  Heading,
  Text,
  Container,
  useMediaQuery,
} from '@chakra-ui/react'
import Marquee from 'react-fast-marquee'

const MarqueeComponent = ({ text }: any) => {
  const [isLargerThan858] = useMediaQuery('(max-width: 858px)')

  return (
    <>
      <Container maxW="container.xl">
        <Heading
          variant="h2"
          as="h3"
          fontSize="18px"
          color="main.spaceGrey"
          marginTop={isLargerThan858 ? '40px' : '70px'}
          marginBottom="40px"
        >
          Where Next ?
        </Heading>
      </Container>

      <Link
        className="link_without_borders"
        sx={{
          transform: 'rotate(-4deg)',
          '& .marquee-container': { height: '300px' },
          overflow: 'hidden',
          width: '100%',
          margin: '0 auto',
        }}
        href="https://ufogaming.io/about"
        position="relative"
        isExternal
      >
        <Marquee
          speed={300}
          gradient={false}
          direction="right"
          className="marquee-container"
        >
          <Heading
            className="link_without_borders"
            as="h3"
            variant="h6"
            color="main.spaceGrey"
            sx={{
              WebkitTextStroke: `1px #102017`,
              color: 'transparent',
              fontSize: '190px',
              margin: '0 10px',
            }}
          >
            {text}
          </Heading>
        </Marquee>
      </Link>
    </>
  )
}

export default MarqueeComponent
