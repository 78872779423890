import { Button } from '@chakra-ui/react'

const ButtonBase = ({ children, ...props }: any) => {
  return (
    <Button
      minHeight="35px"
      minWidth="145px"
      width={props.isFullWidth && '100%'}
      lineHeight="1.2"
      border="0"
      borderRadius="50px"
      fontSize="14px"
      textTransform="capitalize"
      fontWeight="semibold"
      bg={props.white ? 'main.acidGreen' : 'main.spaceGrey'}
      color={props.white ? 'main.spaceGrey' : 'main.white'}
      padding="0 5px 0 15px"
      textAlign="left"
      transition=".3s all ease"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      background="linear-gradient(180deg, #FA937D 0%, #8924F4 100%)"
      sx={{
        '& svg': {
          marginLeft: '10px',
        },
      }}
      _hover={{ opacity: 0.8 }}
      {...props}
    >
      {children}
    </Button>
  )
}

export default ButtonBase
