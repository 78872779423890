import { combineReducers, AnyAction } from 'redux'

// import auth from './auth';
import language from './language'

// import { initialStateAuth } from './auth/reducer';
import { initialStateLanguage } from './language/reducer'
import { CLEAR_ALL_REDUCERS_DATA, RootState } from './types'

export const RootInitialState = {
  //   auth: initialStateAuth,
  language: initialStateLanguage,
}

const allReducers = combineReducers({
  language: language.reducer,
})

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  switch (action.type) {
    case CLEAR_ALL_REDUCERS_DATA:
      return {
        ...RootInitialState,
      }

    default:
      return allReducers(state, action)
  }
}

export default rootReducer
