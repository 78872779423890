import { providers } from 'ethers'
import toast from 'react-hot-toast'
import { Staking_SDK } from 'ufo-sdk'

import { contracts } from '@/App'
import { showNotification } from '@/components/atoms/ToastNotifications'

interface Error {
  code: number | string
  message: string
}

export async function UnstakeFunction(
  lockWeeks: string,
  depositNumber: string | string[],
  tokenType: string,
  signer: any,
  handleWaitingStop?: () => void,
  callback?: () => void
) {
  const instance = new Staking_SDK(
    signer,
    contracts,
    'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17'
  )

  if (tokenType == 'UFO') {
    try {
      const pool = await instance.getUfoPoolWithLockinWeeks(lockWeeks)
      if (Array.isArray(depositNumber)) {
        await pool.withdrawUfoFromMultipleDeposits(depositNumber).then(() => {
          handleWaitingStop && handleWaitingStop()
        })
      } else {
        await pool.withdrawUfoFromDeposit(depositNumber).then(() => {
          handleWaitingStop && handleWaitingStop()
        })
      }

      showNotification.success('Transaction successful')
      if (callback) {
        callback()
      }
    } catch (error) {
      if ((error as Error).code === 4001) {
        handleWaitingStop && handleWaitingStop()
        showNotification.error('You closed Metamask ')
      } else if ((error as Error).code == 'UNPREDICTABLE_GAS_LIMIT') {
        handleWaitingStop && handleWaitingStop()
        showNotification.error('Not enough gas balance')
      } else {
        console.log('error', error)
        handleWaitingStop && handleWaitingStop()

        showNotification.error('Something went wrong')
      }
    }
  } else {
    try {
      const pool = await instance.getLpPoolWithLockinWeeks(lockWeeks)
      if (Array.isArray(depositNumber)) {
        await pool.withdrawLpFromMultipleDeposits(depositNumber).then(() => {
          handleWaitingStop && handleWaitingStop()
        })
      } else {
        await pool.withdrawLpFromDeposit(depositNumber).then(() => {
          handleWaitingStop && handleWaitingStop()
        })
      }

      showNotification.success('Transaction successful')
    } catch (error) {
      if ((error as Error).code === 4001) {
        handleWaitingStop && handleWaitingStop()
        showNotification.error('You closed Metamask ')
      } else if ((error as Error).code == 'UNPREDICTABLE_GAS_LIMIT') {
        handleWaitingStop && handleWaitingStop()
        showNotification.error('Not enough gas balance')
      } else {
        handleWaitingStop && handleWaitingStop()
        showNotification.error('Something went wrong')
      }
    }
  }
}
