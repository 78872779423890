import { Box, Heading, TabList, Tabs } from '@chakra-ui/react'

import { LANGUAGE_SELECT } from '../../utils/constants'

import { BlipTab, ModalPopup } from '@/components'

interface iPropd {
  onCloseModal: () => void
  steLanguage: React.Dispatch<React.SetStateAction<string>>
}

const LanguageSelectPopUp = ({ onCloseModal, steLanguage }: iPropd) => {
  const selectLanguage = (e: any) => {
    steLanguage(e)
  }

  return (
    <Box>
      <ModalPopup
        title="Choose your language"
        isOpen
        onCloseModal={onCloseModal}
        size="xl"
        isCloseButton
      >
        <Tabs
          isFitted
          sx={{
            borderTop: '1px solid #fff',
          }}
        >
          {LANGUAGE_SELECT.map((item, index) => {
            return (
              <TabList
                mb="1em"
                pt={2}
                pb={2}
                sx={{
                  borderBottom: '1px solid #fff',
                }}
              >
                <Heading
                  key={index}
                  color="#FFFFFF"
                  onClick={(e: React.SyntheticEvent) => {
                    selectLanguage((e.target as HTMLElement).innerHTML)
                    onCloseModal()
                  }}
                  cursor="pointer"
                  _hover={{
                    color: '#D2FF59',
                  }}
                  fontSize="46px"
                >
                  {item.language}
                </Heading>
              </TabList>
            )
          })}
        </Tabs>
      </ModalPopup>
    </Box>
  )
}

export default LanguageSelectPopUp
