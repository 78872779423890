export const SUPPORTED_NETWORKS: {
  [chainId: number]: {
    chainId: number
    name: string
    symbol: string
    explorer: string
    rpc: string
  }
} = {
  1: {
    chainId: 1,
    name: 'Mainnet',
    symbol: 'ETH',
    explorer: 'https://etherscan.io/',
    rpc: 'https://mainnet.infura.io/v3/f8a8e8f3f0e94b0c9b8d4c3c2b7a8d12',
  },
  80001: {
    chainId: 80001,
    name: 'Matic Testnet',
    symbol: 'MATIC',
    explorer: 'https://explorer-mumbai.maticvigil.com/',
    rpc: 'https://polygon-mumbai.g.alchemy.com/v2/biSVSVrQJZV4nLjhOaz_S_ljo4K6IwPj',
  },
  5: {
    chainId: 5,
    name: 'Goerli',
    symbol: 'ETH',
    explorer: 'https://goerli.etherscan.io',
    rpc: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  },
  137: {
    chainId: 137,
    name: 'Polygon Mainnet',
    symbol: 'MATIC',
    explorer: 'https://polygonscan.com/',
    rpc: 'https://polygon-rpc.com/',
  },
}

export const AUTO_UPDATE_BALANCE_INTERVAL = 1000 * 5 // 15 seconds
export const DEFAULT_NETWORK = 137
