import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import './index.css'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter as Router } from 'react-router-dom'
import { createConfig, http, WagmiProvider } from 'wagmi'
import { mainnet, polygon } from 'wagmi/chains'

import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store/store'
import { TOASTER_OPTIONS } from './utils/constants'

import { GlobalProvider } from '@/contexts/GlobalContext'
import Fonts from '@/theme/Fonts'
import { theme } from '@/theme/theme'
import { WalletContext, WalletProvider } from '@/web3/WalletContext'
import './assets/fonts/index.css'
import { sentryInit } from '@/utils/sentry'

import '@rainbow-me/rainbowkit/styles.css'
import { getDefaultConfig, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

sentryInit()

export const config = getDefaultConfig({
  appName: 'Botzcoin',
  projectId: '52d22f5b87afa29365ddd627d2715027',
  chains: [polygon, mainnet],
  ssr: true, // If your dApp uses server side rendering (SSR)
})

const queryClient = new QueryClient()

const container: any = document.getElementById('root')
const root = ReactDOM.createRoot(container)
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider>
            <WalletProvider>
              <GlobalProvider>
                <Toaster
                  // position="top-center"
                  containerStyle={{
                    top: 0,
                  }}
                  toastOptions={TOASTER_OPTIONS}
                />
                <Router>
                  <ChakraProvider theme={theme}>
                    <Fonts />
                    <Provider store={store}>
                      <App />
                    </Provider>
                  </ChakraProvider>
                </Router>
              </GlobalProvider>
            </WalletProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </CookiesProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
