import {
  Text,
  Menu,
  MenuButton,
  MenuList,
  useMediaQuery,
} from '@chakra-ui/react'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { FC, useEffect, useState } from 'react'

import {
  getUFOBalance,
  getLPBalance,
  getPlasmaBalance,
} from '../pages/Markets/GetWalletBallance'

import { ReactComponent as UfoIcon } from '@/assets/img/UFO_icon.svg'
import ButtonBase from '@/components/button'
import MyWallet from '@/components/molecules/MyWallet'
import { useWallet } from '@/web3/WalletContext'

interface iConnectWalletProps {
  openVideoModal: () => void
  isWalletVisible?: boolean
}

export const ConnectWallet: FC<iConnectWalletProps> = ({
  openVideoModal,
  isWalletVisible,
}) => {
  const { isConnecting, isConnected, disconnect, address, signer, chainId } =
    useWallet()
  const [ufoBalance, setUfoBalance] = useState<string>()
  const [lpBalance, setLpBalance] = useState<string>()
  const [plamaBalance, setPlasmaBalance] = useState<string>()

  useEffect(() => {
    async function test() {
      const ufoBalance = await getUFOBalance(signer, chainId, address)
      const lpBalance = await getLPBalance(signer, chainId, address)
      const plasmaBalance = await getPlasmaBalance(signer, chainId, address)
      setUfoBalance(ufoBalance)
      setLpBalance(lpBalance)
      setPlasmaBalance(plasmaBalance)
    }
    test()
  }, [ufoBalance, lpBalance, plamaBalance])

  const [isLargerThan480] = useMediaQuery('(max-width: 480px)')

  return (
    <>
      {!isConnected && (
        <>
          <ConnectButton.Custom>
            {({
              account,
              chain,
              openAccountModal,
              openChainModal,
              openConnectModal,
              authenticationStatus,
              mounted,
            }) => {
              return (
                <ButtonBase
                  size="sm"
                  background=" linear-gradient(180deg, #FA937D 0%, #8924F4 100%);"
                  color="white"
                  disabled={isConnecting}
                  onClick={() => !isConnected && openConnectModal()}
                  display={isLargerThan480 ? 'none' : 'flex'}
                >
                  {isConnecting
                    ? 'Connecting...'
                    : isConnected
                    ? 'Connected'
                    : 'Connect Wallet'}
                  <UfoIcon width={29} height={29} />
                </ButtonBase>
              )
            }}
          </ConnectButton.Custom>
        </>
      )}
      {isConnected && (
        <>
          <Menu>
            <MenuButton bgSize="sm" display={isLargerThan480 ? 'none' : 'flex'}>
              <ButtonBase padding=" 0 3px 0 12px" maxHeight="35px">
                <Text fontSize="14px" fontWeight="400">
                  My Wallet
                </Text>
                <UfoIcon width="28px" height="28px" />
              </ButtonBase>
            </MenuButton>
            <MenuList
              boxShadow="0px 0px 10px #000"
              bg="main.spaceGrey"
              border="none"
              borderRadius="27px"
              minWidth="345px"
              maxWidth="345"
              sx={{ display: isWalletVisible ? '' : 'none' }}
            >
              <MyWallet
                address={address}
                disconnect={disconnect}
                openVideoModal={openVideoModal}
              />
            </MenuList>
          </Menu>
        </>
      )}
    </>
  )
}
