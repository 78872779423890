import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Box,
  Text,
  Center,
  Link,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { getLPBalance, getUFOBalance } from './GetWalletBallance'
import { StakingFunction } from './StakingFunction'
import { getMyStakes, getAllPools, getPoolAprs } from './service'

import { ReactComponent as UfoIcon } from '@/assets/img/UFO_icon.svg'
import { ReactComponent as ArrowOnPurple } from '@/assets/img/arrowOnPurple.svg'
import { ReactComponent as BridgeWithBackground } from '@/assets/img/bridgeWithBackground.svg'
import { BlipTab, ModalPopup, RangeSlider } from '@/components'
import { showNotification } from '@/components/atoms/ToastNotifications'
import ButtonBase from '@/components/button'
import { getPoolWeights } from '@/components/pages/Markets/service'
import {
  UFOETHLP_STAKING_CONTRACT_ADDRESS,
  UFO_STAKING_CONTRACT_ADDRESS,
  UFO_TOKEN_CONTRACT_ADDRESS,
  UFOETHLP_TOKEN_CONTRACT_ADDRESS,
  STAKE_RANGES,
} from '@/utils/constants'
import { useWallet } from '@/web3/WalletContext'

interface StakeModalProps {
  isOpen: boolean
  modalType: number
  onCloseModal: () => void
  setModalType: (type: number) => void
}

const fetchPoolWeights = async () => {
  const poolWeights = await getPoolWeights()
  return poolWeights
}

const TabContent = ({
  // stakingContractAddress,
  // tokenContractAddress,
  onCloseModal,
  selectedToken,
  weights,
}: {
  stakingContractAddress: string
  tokenContractAddress: string
  onCloseModal: () => void
  selectedToken: string
  weights: any
}) => {
  const [tokenBalance, setTokenBalance] = useState<string>('')
  const [isLoading, setIsloading] = useState<boolean>(false)
  const [waiting, setWaiting] = useState(false)
  const [btnText, setBtnText] = useState('Approve Staking')
  const [pools, setAllPools] = useState()
  const { signer, chainId, address } = useWallet()

  useEffect(() => {
    const test = async () => {
      setIsloading(true)
      const allPools = await getAllPools()
      // const poolAprs = await getPoolAprs()
      const ufoBalance = await getUFOBalance(signer, chainId, address)
      const lpBalance = await getLPBalance(signer, chainId, address)

      setIsloading(false)
      if (selectedToken == '1') {
        setTokenBalance(ufoBalance)
        const ufoPools = allPools.filter((item) => item.poolType === 'UFO_POOL')
        setAllPools(ufoPools)
      } else {
        setTokenBalance(lpBalance)
        const lpPools = allPools.filter((item) => item.poolType === 'LP_POOL')
        setAllPools(lpPools)
      }
    }
    test()
  }, [selectedToken])

  const [lockValue, setLockValue] = useState('52')
  const [lockAmount, setLockAmount] = useState<string>('')

  const handleWaitingStop = () => {
    setWaiting(false)
    setBtnText('Stake')
    setLockAmount('')
  }

  const approveUFO = async () => {
    if (!lockAmount || Number(lockAmount) <= 0) {
      showNotification.error('Please enter a valid amount')
      return
    }

    if (Number(lockAmount) > Number(tokenBalance)) {
      console.log(
        'You do not have enough UFO to stake',
        lockAmount,
        tokenBalance
      )

      toast.error('You do not have enough tokens to stake.')
      return
    }

    getMyStakes(address)

    const tryStaking = async (
      lockValue: any,
      selectedToken: any,
      lockAmount: any
    ) => {
      setWaiting(true)
      await StakingFunction(
        lockValue,
        selectedToken,
        lockAmount,
        handleWaitingStop,
        signer
      )
    }
    tryStaking(lockValue, selectedToken, lockAmount)
  }

  return (
    <>
      <Box
        display="flex"
        mb={2}
        justifyContent="space-between"
        pl="20px"
        pr="20px"
      >
        <Text color="main.white" variant="caption">
          {selectedToken === '1' ? 'UFO' : 'UFO-ETH LP'}
        </Text>
        <Text color="main.white" variant="caption">
          Balance:
          {isLoading
            ? 'Loading...'
            : selectedToken === '1'
            ? Number(tokenBalance).toFixed(0)
            : Number(tokenBalance).toFixed(2)}
        </Text>
      </Box>

      <Box>
        <InputGroup>
          <Input
            type="number"
            placeholder="Enter amount"
            size="lg"
            onChange={(e) => setLockAmount(e.target.value)}
            value={lockAmount}
            borderRadius="50px"
          />
          <InputRightElement width="4.2">
            <Button
              h="1.75"
              size="sm"
              variant="ghost"
              color="main.purpleHighlight"
              mt="2"
              onClick={() => setLockAmount(tokenBalance)}
            >
              MAX
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>
      <Box display="flex" alignItems="center" flexWrap="wrap" mb="10px">
        <Link
          isExternal
          fontStyle="normal"
          fontWeight="400"
          fontSize="14px"
          lineHeight="20px"
          mt="8px"
          className="link_without_borders"
          href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x249e38ea4102d0cf8264d3701f1a0e39c4f2dc3b&chain=mainnet"
        >
          <ButtonBase
            minW="126px"
            w="126"
            h="39px"
            position="relative"
            background="linear-gradient(180deg, #FA937D 0%, #8924F4 100%)"
            color="main.acidGreen"
            justifyContent="center"
            p="2px"
            overflow="hidden"
            outline="none"
            fontStyle="normal"
            opacity="0.9"
            _hover="none"
            mr="20px"
          >
            <Center
              w="100%"
              h="100%"
              background="#102017"
              borderRadius="50px"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight="20px"
              pl="19px"
              pr="8px"
              justifyContent="space-between"
            >
              Get UFO
              <ArrowOnPurple width={23} height={23} />
            </Center>
          </ButtonBase>
        </Link>
        {selectedToken === '1' ? (
          <Text
            color="white"
            mt="8px"
            fontSize="12px"
            fontWeight="400"
            lineHeight="19px"
          >
            Don't have tokens? Buy now on Uniswap!
          </Text>
        ) : null}
        {selectedToken === '2' ? (
          <Link
            isExternal
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight="20px"
            className="link_without_borders"
            href="https://app.uniswap.org/#/add/v2/ETH/0x249e38Ea4102D0cf8264d3701f1a0E39C4f2DC3B?chain=mainnet"
            mt="8px"
          >
            <ButtonBase
              minW="126px"
              w="126"
              h="39px"
              position="relative"
              background="linear-gradient(180deg, #FA937D 0%, #8924F4 100%)"
              color="main.acidGreen"
              justifyContent="center"
              p="2px"
              overflow="hidden"
              outline="none"
              fontStyle="normal"
              opacity="0.9"
              _hover="none"
              mr="20px"
            >
              <Center
                w="100%"
                h="100%"
                background="#102017"
                borderRadius="50px"
                fontStyle="normal"
                fontWeight="400"
                fontSize="14px"
                lineHeight="20px"
                pl="19px"
                pr="8px"
                justifyContent="space-between"
              >
                Get UFO-ETH LP
                <ArrowOnPurple width={23} height={23} />
              </Center>
            </ButtonBase>
          </Link>
        ) : null}
      </Box>
      <Box display="flex" alignItems="center" flexWrap="wrap" mb="20px">
        <Link
          href="https://wallet.polygon.technology/polygon/bridge/deposit"
          isExternal
          sx={{ textDecoration: 'none !important' }}
        >
          <ButtonBase
            minW="126px"
            w="126"
            h="39px"
            position="relative"
            background="linear-gradient(180deg, #FA937D 0%, #8924F4 100%)"
            color="main.acidGreen"
            justifyContent="center"
            p="2px"
            overflow="hidden"
            outline="none"
            fontStyle="normal"
            opacity="0.9"
            _hover="none"
            mr="20px"
            onClick={() => {
              onCloseModal()
            }}
          >
            <Center
              w="100%"
              h="100%"
              background="#102017"
              borderRadius="50px"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight="20px"
              pl="19px"
              pr="8px"
              justifyContent="space-between"
            >
              Bridge
              <BridgeWithBackground width={23} height={23} />
            </Center>
          </ButtonBase>
        </Link>
      </Box>
      {weights && pools && (
        <RangeSlider
          poolType={selectedToken === '1' ? 'UFO' : 'UFO-ETH LP'}
          weights={weights}
          pools={pools}
          value={lockValue}
          setValue={setLockValue}
        />
      )}
      <ButtonBase
        isFullWidth
        onClick={approveUFO}
        disabled={waiting || isLoading}
        isLoading={waiting || isLoading}
        loadingText="Processing..."
        padding="5px 5px 5px 20px"
        color="main.cream"
        h="55px"
        justifyContent={isLoading || waiting ? 'center' : 'space-between'}
      >
        {waiting ? 'In Progress ' : btnText}
        <UfoIcon width={45} height={45} />
      </ButtonBase>
    </>
  )
}

export const StakeModal: FC<StakeModalProps> = ({
  isOpen,
  onCloseModal,
  modalType,
  setModalType,
}) => {
  const [selectedToken, setSelectedToken] = useState<string>('0')
  const [weights, setWeights] = useState<any[]>([])

  useEffect(() => {
    fetchPoolWeights().then((data: any) => {
      setWeights(data)
    })
  }, [])

  useEffect(() => {
    setSelectedToken(String(modalType))
  }, [modalType])

  return (
    <ModalPopup
      title="Start staking"
      isOpen={isOpen}
      onCloseModal={onCloseModal}
    >
      <Tabs index={modalType - 1} isFitted>
        <TabList
          mb="1em"
          pt={10}
          pb={10}
          sx={{ borderBottom: '1px solid #fff', borderTop: '1px solid #fff' }}
        >
          <Box
            display="flex"
            width="100%"
            backgroundColor="#102017"
            borderRadius="71px"
          >
            <BlipTab
              onClick={() => {
                setModalType(1)
              }}
            >
              UFO
            </BlipTab>
            <BlipTab
              onClick={() => {
                setModalType(2)
              }}
            >
              UFO-ETH LP
            </BlipTab>
          </Box>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TabContent
              stakingContractAddress={UFO_STAKING_CONTRACT_ADDRESS}
              tokenContractAddress={UFO_TOKEN_CONTRACT_ADDRESS}
              onCloseModal={onCloseModal}
              selectedToken={selectedToken}
              weights={weights}
            />
          </TabPanel>
          <TabPanel>
            <TabContent
              stakingContractAddress={UFOETHLP_STAKING_CONTRACT_ADDRESS}
              tokenContractAddress={UFOETHLP_TOKEN_CONTRACT_ADDRESS}
              onCloseModal={onCloseModal}
              selectedToken={selectedToken}
              weights={weights}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ModalPopup>
  )
}
