import './style.css'
import { Box } from '@chakra-ui/react'
import { useState, useEffect, useRef, FC } from 'react'

import poster from '@/assets/img/previewFrame.jpg'
import { ReactComponent as Rectangle } from '@/assets/img/rectangle.svg'
const DEFAULT_VIDEO_LINK =
  'https://storage.ufogaming.io/ufogaming-videos/UFO%20Staking%20with%20Intro%20and%20Outro.mp4'

interface iVideoPlayerProps {
  muted?: boolean
  loop?: boolean
  controls?: boolean
  autoplay?: boolean
  preload?: string
  link?: string
  radius?: string
}

export const VideoPlayer: FC<iVideoPlayerProps> = (props) => {
  const {
    link = DEFAULT_VIDEO_LINK,
    muted = false,
    loop = false,
    controls = false,
    preload = 'metadata',
    autoplay = false,
    radius = '72px',
  } = props
  const videoPlayerRef = useRef<any>(null)
  const [play, setPlay] = useState<boolean>(false)

  const handlePlayButtonClick = (event): any => {
    event.stopPropagation()
    setPlay(true)
  }

  const handlePlay = (): void => {
    videoPlayerRef.current.play()
    videoPlayerRef.current.classList.remove('videoPlayer__video--paused')
  }

  const handlePause = (): void => {
    videoPlayerRef.current.pause()
    videoPlayerRef.current.classList.add('videoPlayer__video--paused')
  }

  const handleVideoEnded = (): void => {
    setPlay(false)
    videoPlayerRef.current.load()
  }

  const handlePlayPauseVideo = () => (play ? handlePlay() : handlePause())

  useEffect(() => {
    handlePlayPauseVideo()
  }, [play])

  return (
    <Box
      sx={{ width: '100%' }}
      // borderRadius={{ base: '27px', sm: '72px' }}
      overflow="hidden"
      position="relative"
      className="videoPlayer"
    >
      <div className="videoPlayer__video-wrap">
        <video
          ref={videoPlayerRef}
          className="videoPlayer__video"
          loop={loop}
          controls={controls}
          preload={preload}
          muted={muted}
          poster={poster}
          onEnded={handleVideoEnded}
          autoPlay={autoplay}
          style={{
            borderRadius: radius,
          }}
        >
          <source src={`${link}`} type="video/mp4" />
        </video>
      </div>
      {!controls && (
        <div className="videoPlayer__controls" onClick={() => setPlay(false)}>
          {!play && (
            <button
              className="videoPlayer__button"
              onClick={handlePlayButtonClick}
            >
              <Rectangle />
            </button>
          )}
        </div>
      )}
    </Box>
  )
}
