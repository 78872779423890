import { Tab, useTab, UseTabProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

export const BlipTab = forwardRef<HTMLElement, UseTabProps>((props, ref) => {
  const tabProps = useTab({ ...props, ref })
  const isSelected = tabProps['aria-selected']

  return (
    <Tab
      position="relative"
      _selected={{ color: 'main.spaceGrey', bg: 'main.whiteBlue' }}
      color="main.white"
      borderRadius="71px"
      {...tabProps}
      minW="max-content"
      className="link_without_borders"
    >
      {tabProps.children}
    </Tab>
  )
})
