import {
  Box,
  Flex,
  VStack,
  Heading,
  Text,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useMediaQuery,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Accordion from './Accordion'
import { RootState } from '../../store/types'
import { Leaderboard } from '../pages/Markets/Leaderboard'

import { ReactComponent as UfoIcon } from '@/assets/img/UFO_icon.svg'
import MarqueeComponent from '@/components/Mark'
import NeedSomeUfpPage from '@/components/NeedSomeUFO'
import StakesPage from '@/components/Stakes'
import UnclaimedRewards from '@/components/UnclaimedRewards'
import ButtonBase from '@/components/button'
import { VideoPlayer } from '@/components/molecules/VideoPlayer/index'
import PageBall from '@/components/pageBall'
import PageSmallBall from '@/components/pageBall/smallBall'
import StakedValues from '@/components/startScreen/StakedValues'
import { useWallet } from '@/web3/WalletContext'
const StakesBox = ({ add, ufo, usd }: any) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 0 ',
        borderBottom: '1px solid #111',
      }}
    >
      <Box>
        <Heading
          variant="h2"
          as="h3"
          fontSize="16px"
          color="main.spaceGrey"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '90%',
          }}
        >
          {add}
        </Heading>
      </Box>
      <Box>
        <Box>
          <Heading variant="h2" as="h3" size="xs" color="main.purpleHighlight">
            {ufo}
          </Heading>
        </Box>
        <Box>
          <Heading variant="h2" as="h3" fontSize="9px" color="main.spaceGrey">
            {usd}
          </Heading>
        </Box>
      </Box>
    </Box>
  )
}

const StakingStartView = () => {
  const { selectedLanguage } = useSelector((state: RootState) => state.language)
  const {
    body: { video_title, connect_wallet, stakes, recent, leaderboard, faq },
  } = selectedLanguage

  const stakData = ['5', '5', '11,833,912.00', '5,434,000.00']

  const { isConnecting, isConnected, disconnect, address } = useWallet()
  const [isLargerThan884] = useMediaQuery('(max-width:984px)')

  const [tabIndex, setTabIndex] = useState(0)

  const [click, setClick] = useState(false)

  return (
    <>
      <Flex
        w="full"
        flexDirection="column"
        alignItems="flex-start"
        margin="40px 0"
      >
        <Container maxW="container.xl">
          <Box w="full" sx={{ marginBottom: '95px' }}>
            <StakedValues stakData={stakData} />
          </Box>
          <Box w="full" sx={{ marginBottom: '40px' }}>
            <Box position="relative">
              <Heading
                variant="h2"
                as="h3"
                fontSize={{ base: '18px', sm: '24px' }}
                color="main.spaceGrey"
                sx={{ marginBottom: '20px' }}
              >
                <Box zIndex="2" id="how_to_stake">
                  {video_title}
                </Box>

                <Box position="absolute" zIndex="-1">
                  <PageSmallBall />
                </Box>
              </Heading>
            </Box>

            <VideoPlayer />
          </Box>
          {/* <Box w="full" mb={20} display="none">
            <Tabs onChange={(index) => setTabIndex(index)} variant="unstyled">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingBottom: '20px',
                  borderBottom: '1px solid #111',
                }}
                mb={10}
              >
                <Heading variant="h2" as="h3" size="xl" color="main.spaceGrey">
                  {stakes}
                </Heading>
              </Box>
              <TabPanels>
                <TabPanel>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Box sx={{ width: '48%' }}>
                      <Heading
                        variant="h2"
                        as="h3"
                        size="sm"
                        color="main.spaceGrey"
                        mb={2}
                      >
                        UFO Staking
                      </Heading>

                      <StakesBox
                        add="0x12qehkj1238789q3hjk123u8wue8w789er"
                        ufo="UFO 11,100.0"
                        usd="USD 19,000.0"
                      />
                    </Box>
                    <Box sx={{ width: '48%' }}>
                      <Heading
                        variant="h2"
                        as="h3"
                        size="sm"
                        color="main.spaceGrey"
                        mb={2}
                      >
                        UFO-ETH LP Staking
                      </Heading>

                      <StakesBox
                        add="0x12qehkj1238789q3hjk123u8wue8w789er"
                        ufo="UFO 11,100.0"
                        usd="USD 19,000.0"
                      />
                      <StakesBox
                        add="0x12qehkj1238789q3hjk123u8wue8w789er"
                        ufo="UFO 11,100.0"
                        usd="USD 19,000.0"
                      />
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Box sx={{ width: '48%' }}>
                      <Heading
                        variant="h2"
                        as="h3"
                        size="sm"
                        color="main.spaceGrey"
                        mb={2}
                      >
                        UFO Staking
                      </Heading>

                      <StakesBox
                        add="0x12qehkj1238789q3hjk123u8wue8w789er"
                        ufo="UFO 11,100.0"
                        usd="USD 19,000.0"
                      />
                      <StakesBox
                        add="0x12qehkj1238789q3hjk123u8wue8w789er"
                        ufo="UFO 11,100.0"
                        usd="USD 19,000.0"
                      />
                      <StakesBox
                        add="0x12qehkj1238789q3hjk123u8wue8w789er"
                        ufo="UFO 11,100.0"
                        usd="USD 19,000.0"
                      />
                    </Box>
                    <Box sx={{ width: '48%' }}>
                      <Heading
                        variant="h2"
                        as="h3"
                        size="sm"
                        color="main.spaceGrey"
                        mb={2}
                      >
                        UFO-ETH LP Staking
                      </Heading>

                      <StakesBox
                        add="0x12qehkj1238789q3hjk123u8wue8w789er"
                        ufo="UFO 11,100.0"
                        usd="USD 19,000.0"
                      />
                      <StakesBox
                        add="0x12qehkj1238789q3hjk123u8wue8w789er"
                        ufo="UFO 11,100.0"
                        usd="USD 19,000.0"
                      />
                      <StakesBox
                        add="0x12qehkj1238789q3hjk123u8wue8w789er"
                        ufo="UFO 11,100.0"
                        usd="USD 19,000.0"
                      />
                    </Box>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box> */}
          {/* <Flex justifyContent="space-between" id="leaderboard">
            <Heading
              variant="h2"
              as="h3"
              size="sm"
              color="main.spaceGrey"
              mb={2}
              fontSize="24px"
            >
              {stakes}
            </Heading>
            <Box display={isLargerThan884 ? 'none' : 'flex'}>
              <Text
                cursor="pointer"
                color={!click ? ' #A65AF6' : ''}
                onClick={() => {
                  setClick(!click)
                }}
              >
                {recent}
              </Text>

              <Text
                marginLeft="20px"
                color={click ? ' #A65AF6' : ''}
                cursor="pointer"
                onClick={() => {
                  setClick(!click)
                }}
              >
                {leaderboard}
              </Text>
            </Box>
          </Flex>
          <Box border=" 1px solid #102017 " />
          <StakesPage /> */}
          <Leaderboard />
          <NeedSomeUfpPage />
          {/* <Box
            display={isLargerThan884 ? 'flex' : 'none'}
            justifyContent="space-between"
            marginTop="32px"
          >
            <Text
              cursor="pointer"
              color={!click ? ' #A65AF6' : ''}
              onClick={() => {
                setClick(!click)
              }}
            >
              {recent}
            </Text>
            <Text
              marginLeft="20px"
              color={click ? ' #A65AF6' : ''}
              cursor="pointer"
              onClick={() => {
                setClick(!click)
              }}
            >
              {leaderboard}
            </Text>
          </Box> */}
          <Box w="full" mb={20} id="faq">
            <Heading
              variant="h2"
              as="h3"
              size="xl"
              color="main.spaceGrey"
              sx={{
                paddingBottom: '20px',
                borderBottom: '1px solid #111',
                marginBottom: '40px',
                marginTop: '56px',
              }}
            >
              FAQ
            </Heading>
            <Accordion />
          </Box>
        </Container>

        <MarqueeComponent text="ABOUT US" />
      </Flex>
    </>
  )
}

export { StakingStartView }
