import { BigNumber, ethers, providers } from 'ethers'
import toast from 'react-hot-toast'
import { Staking_SDK } from 'ufo-sdk'

import { contracts } from '@/App'
import { showNotification } from '@/components/atoms/ToastNotifications'

interface Error {
  code: number | string
  message: string
}

export async function StakingFunction(
  lockValue: string,
  selectedToken: string,
  lockAmount: string,
  handleWaitingStop: () => void,
  signer
) {
  const instance = new Staking_SDK(
    signer,
    contracts,
    'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17'
  )

  if (selectedToken == '1') {
    var pool = await instance.getUfoPoolWithLockinWeeks(lockValue)
  } else {
    var pool = await instance.getLpPoolWithLockinWeeks(lockValue)
  }
  const hasApproval = await pool.hasApprovalForDeposit(`${lockAmount}`)
  if (hasApproval) {
    try {
      showNotification.success('Please confirm your transaction in Metamask')
      // console.log('if', hasApproval)
      // await pool.cancelTokenApproval()
      console.log('pool address -- ', pool)
      await pool.deposit(`${lockAmount}`).then(async (tx) => {
        await tx.wait(1).then(() => {
          handleWaitingStop()
        })
      })
      showNotification.success('Transaction successful')
    } catch (error) {
      if ((error as Error).code === 4001) {
        handleWaitingStop()
        showNotification.error('You closed Metamask ')
      } else if ((error as Error).code == 'UNPREDICTABLE_GAS_LIMIT') {
        handleWaitingStop()
        showNotification.error('Not enough gas balance')
      } else {
        handleWaitingStop()
        showNotification.error('Something went wrong')
      }
    }
  } else {
    try {
      showNotification.success(
        'Please confirm your transaction in Metamask and wait '
      )
      // console.log('else', hasApproval)
      // await pool.cancelTokenApproval()
      await pool.approveTokenForDeposit().then(async (tx) => {
        await tx.wait(1).then(async () => {
          await pool.deposit(`${lockAmount}`).then(() => {
            handleWaitingStop()
          })
        })
      })
      showNotification.success('Transaction successful')
    } catch (error) {
      if ((error as Error).code === 4001) {
        handleWaitingStop()
        showNotification.error('You closed Metamask ')
      } else if ((error as Error).code == 'UNPREDICTABLE_GAS_LIMIT') {
        handleWaitingStop()
        showNotification.error('Not enough gas balance')
      } else {
        handleWaitingStop()
        showNotification.error('Something went wrong')
      }
    }
  }
}
