import {
  Box,
  Center,
  Flex,
  Text,
  Heading,
  useMediaQuery,
} from '@chakra-ui/react'
import { FC } from 'react'
import NumberFormat from 'react-number-format'

import { ReactComponent as ArrowOnPurple } from '@/assets/img/arrowOnPurple.svg'
import ButtonBase from '@/components/button'

interface iMyStake {
  pool: string
  amount: string
  type: string
  APR: string
  UFO: string
  PLASMA: string
  date: string
  isDepositWithdrawable: string
  opacityProp?: string
  openModal: () => void
  claimPlasma: () => void
}

const MyStakes: FC<iMyStake> = (props) => {
  const [isSmallerThan992] = useMediaQuery('(max-width: 992px)')
  const {
    pool,
    amount,
    type,
    APR,
    UFO,
    PLASMA,
    date,
    opacityProp,
    openModal,
    claimPlasma,
    isDepositWithdrawable,
  } = props

  return (
    <Flex
      bg="#102017"
      borderRadius="27px"
      p={{ base: '30px', lg: '8px' }}
      w="100%"
      color="white"
      justifyContent="space-between"
      alignItems="center"
      flexDirection={{ base: 'column', lg: 'row' }}
    >
      <Flex w="100%" m={{ base: 'none', md: '3px 30px 0' }}>
        <Box
          display="flex"
          justifyContent="space-around"
          w="100%"
          flexDirection={{ base: 'column', lg: 'row' }}
        >
          <Box fontWeight="700" fontSize="14px" lineHeight="20px" w="100%">
            <Flex flexDirection={{ base: 'column', lg: 'row' }}>
              <Box>
                <Text
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="19px"
                  display={{ base: 'inline', lg: 'none' }}
                  fontStyle="normal"
                >
                  Pool
                </Text>
              </Box>
              <Box>
                <Text
                  letterSpacing="0.442105px"
                  fontStyle="normal"
                  fontSize="14px"
                  fontWeight="bold"
                >
                  {pool === 'LP' ? 'UFO-ETH LP' : pool}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box fontWeight="700" fontSize="14px" lineHeight="20px" w="100%">
            <Flex flexDirection={{ base: 'column', lg: 'row' }}>
              <Box>
                <Text
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="19px"
                  display={{ base: 'inline', lg: 'none' }}
                >
                  Staked Amount
                </Text>
              </Box>
              <Box>
                <Text
                  letterSpacing="0.442105px"
                  fontStyle="normal"
                  fontSize="14px"
                  fontWeight="700"
                >
                  <NumberFormat
                    value={amount}
                    displayType="text"
                    thousandSeparator
                    renderText={(value) => <>{value}</>}
                  />
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box fontWeight="700" fontSize="14px" lineHeight="20px" w="100%">
            <Flex flexDirection={{ base: 'column', lg: 'row' }}>
              <Box>
                <Text
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="19px"
                  display={{ base: 'inline', lg: 'none' }}
                >
                  Type
                </Text>
              </Box>
              <Box>
                <Text
                  letterSpacing="0.442105px"
                  fontStyle="normal"
                  fontSize="14px"
                  fontWeight="700"
                >
                  {type}
                </Text>
              </Box>
            </Flex>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-around"
          w="100%"
          flexDirection={{ base: 'column', lg: 'row' }}
        >
          <Box
            fontWeight="700"
            fontSize="14px"
            lineHeight="20px"
            w={{ base: '100%', lg: '50%' }}
          >
            <Flex flexDirection={{ base: 'column', lg: 'row' }}>
              <Box>
                <Text
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="19px"
                  display={{ base: 'inline', lg: 'none' }}
                  float={{ base: 'right', lg: 'none' }}
                >
                  APR
                </Text>
              </Box>
              <Box>
                <Text
                  letterSpacing="0.442105px"
                  fontStyle="normal"
                  fontSize="14px"
                  fontWeight="700"
                  float={{ base: 'right', lg: 'none' }}
                >
                  {APR}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box fontWeight="700" fontSize="14px" lineHeight="20px" w="100%">
            <Flex
              flexDirection={{ base: 'column', lg: 'row' }}
              justifyContent="center"
              w="100%"
            >
              <Box padding-right="5%">
                <Text
                  fontWeight="400"
                  fontSize={{ base: '9px', lg: '12px' }}
                  lineHeight="19px"
                  display={{ base: 'inline', lg: 'none' }}
                  float={{ base: 'right', lg: 'none' }}
                >
                  Rewards: PLASMA | UFO
                </Text>
              </Box>
              <Flex gap="5px" justifyContent={{ base: 'flex-end', lg: 'none' }}>
                <Text
                  color="#A65AF6"
                  letterSpacing="0.442105px"
                  fontStyle="normal"
                  fontSize="14px"
                  fontWeight="700"
                >
                  {PLASMA}
                </Text>
                <Text> | </Text>
                <Text
                  color="#59FFCF"
                  letterSpacing="0.442105px"
                  fontStyle="normal"
                  fontSize="14px"
                  fontWeight="700"
                >
                  {UFO}
                </Text>
              </Flex>
            </Flex>
          </Box>

          <Box fontWeight="700" fontSize="14px" lineHeight="20px" w="100%">
            <Flex
              flexDirection={{ base: 'column', lg: 'row' }}
              justifyContent="center"
              w="100%"
            >
              <Box>
                <Text
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="19px"
                  display={{ base: 'inline', lg: 'none' }}
                  float={{ base: 'right', lg: 'none' }}
                  fontStyle="normal"
                >
                  Date
                </Text>
              </Box>
              <Box>
                <Text
                  letterSpacing="0.442105px"
                  fontStyle="normal"
                  fontSize="14px"
                  fontWeight="700"
                  float={{ base: 'right', lg: 'none' }}
                >
                  {date}
                </Text>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>
      <Flex
        gap={{ base: '32px', lg: '8px' }}
        w={{ base: '100%', lg: 'auto' }}
        mt={{ base: '16px', lg: '0px' }}
      >
        <Box w={{ base: '100%', lg: 'auto' }}>
          <ButtonBase
            minW="110px"
            w="100%"
            h="39px"
            isDisabled={
              typeof isDepositWithdrawable === 'boolean' &&
              !isDepositWithdrawable
            }
            position="relative"
            background="linear-gradient(180deg, #FA937D 0%, #8924F4 100%)"
            color="main.orange"
            justifyContent="center"
            p="2px"
            overflow="hidden"
            outline="none"
            fontStyle="normal"
            opacity={
              typeof isDepositWithdrawable === 'boolean' &&
              !isDepositWithdrawable
                ? '0.3'
                : 1
            }
            _hover="none"
            onClick={openModal}
          >
            <Center
              w="100%"
              h="100%"
              background="#102017"
              borderRadius="50px"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight="20px"
            >
              Unstake
            </Center>
          </ButtonBase>
        </Box>

        <Box w={{ base: '100%', lg: 'auto' }}>
          <ButtonBase
            minW="110px"
            h="39px"
            w="100%"
            position="relative"
            fontWeight="400"
            lineHeight="20px"
            border="1px solid #FA937D"
            justifyContent={{ sm: 'center', lg: 'flex-start' }}
            pl={{ sm: '0', lg: '26px' }}
            fontStyle="normal"
            pt="2px"
            pb="2px"
            color="#F8F4E4"
            outline="none"
            onClick={claimPlasma}
          >
            Claim
            <ArrowOnPurple style={{ position: 'absolute', right: '4px' }} />
          </ButtonBase>
        </Box>
      </Flex>
    </Flex>
  )
}

export default MyStakes
