import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { FC, useState } from 'react'

import StakesPage from '@/components/Stakes'

const TAB_RECENT_VALUE = 'recent'
const TAB_LEADERBOARD_VALUE = 'leaderboard'

export const Leaderboard: FC = () => {
  const [chosenTab, setChosenTab] = useState<string>(TAB_LEADERBOARD_VALUE)

  return (
    <Box id="leaderboard">
      <Box marginTop="50px">
        <Flex justify="space-between" marginBottom="20px" alignItems="baseline">
          <Heading variant="h5" as="h3" size="lg">
            Leaderboard
          </Heading>
          <Flex alignItems="flex-end" gap="20px">
            <Text
              color={
                chosenTab === TAB_LEADERBOARD_VALUE ? '#102017' : '#A65AF6'
              }
              cursor="pointer"
              fontWeight="700"
              fontSize="16px"
              onClick={() => setChosenTab(TAB_LEADERBOARD_VALUE)}
            >
              Leaderboard
            </Text>
            <Text
              color={chosenTab === TAB_RECENT_VALUE ? '#102017' : '#A65AF6'}
              cursor="pointer"
              fontWeight="700"
              fontSize="16px"
              onClick={() => setChosenTab(TAB_RECENT_VALUE)}
            >
              Recent
            </Text>
          </Flex>
        </Flex>
        <Box border=" 1px solid #102017" />
        <StakesPage order={chosenTab} />
      </Box>
    </Box>
  )
}
