import { AnyAction } from 'redux'

import {
  CHANGE_LANGUAGE,
  LanguageInitialState,
  SET_LP_TOTAL_POOL,
  SET_UFO_TOTAL_POOL,
  stakeData,
} from './types'

export const initialStateLanguage: LanguageInitialState = {
  selectedLanguage: {
    body: {
      now: 'Now',
      title: 'Staking',
      Amount: 'Amount, UFO',
      recent: 'Recent',
      stakes: 'Stakes',
      min_ago: 'min ago',
      subtitle: 'Stake Your Tokens, Play the Game',
      sub_title_footer:
        'Staking rewards enter a 12 month vesting period after claiming.',
      Stake_UFO: 'Stake UFO',
      staked_at: 'Staked at',
      current_TVL: 'Current TVL',
      leaderboard: 'Leaderboard',
      video_title: 'Staking Tutorial',
      connect_wallet: 'Connect wallet',
      locked_staking: 'Locked staking',
      wallet_address: '',
      approximate_APR: 'Approximate APR',
      take_UFO_ETH_LP: 'Stake UFO-ETH LP',
      flexible_staking: 'Flexible staking',
      total_UFO_staked: 'Total UFO Staked',
      desc: 'You will need to stake UFO or UFO-ETH LP in order to earn Plasma Points. These can be traded for in-game items and Genesis NFT soldiers which you will need to play our debut game Super Galactic.',
      subtitle_description: `Earn $UFO and Plasma Points by staking your UFO tokens. One deposit, multiple rewards.`,
      total_UFO_ETH_LP_staked: 'Total UFO-ETH LP Staked',
      faq: 'FAQ',
      coming_soon: 'coming soon',
    },
    footer: {
      about: 'About',
      dark_metaverse: 'Dark Metaverse',
      available_on: 'Available on',
      games: 'Games',
      get_UFO: 'Get UFO',
      cariers: 'Careers',
      info: 'UFOgaming.io © 2022, All rights reserved',
      join_the_UFO_community: 'Join the UFO community',
      links: 'Links',
      privacy_Policy: 'Privacy Policy',
      staking: 'Staking',
      terms_and_conditions: 'Terms and conditions',
      tournaments: 'Tournaments',
      web_design_by: 'Web design by',
      charities: 'Charities',
    },
    header: {
      leaderboard: 'Leaderboard',
      FAQ: 'FAQ',
      how_to_stake: 'How to stake',
      language: 'ENG',
    },
  },
}

export const initialStakeData: stakeData = {
  ufoTotalPool: '',
  LpTotalPool: '',
  ufoTvl: '',
  LpTvl: '',
  ufoCount: '',
  LpCount: '',
  plasmaCount: '',
}

export default function reducer(
  state = initialStateLanguage,
  action: AnyAction
) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.payload.selectedLanguage,
      }
    case SET_UFO_TOTAL_POOL:
      return {
        ...state,
        ufoTotalPool: action.payload.selectedLanguage,
      }
    case SET_LP_TOTAL_POOL:
      return {
        ...state,
        LpTotalPool: action.payload.LpPools,
      }
    default:
      return state
  }
}
