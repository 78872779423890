import { BigNumber } from 'ethers'
import { Subgraph } from 'ufo-sdk'

const isProduct = true
const subgraphInstance = new Subgraph(
  'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17',
  isProduct
)

export const getAllPools = async () => {
  const subgraphInstance = new Subgraph(
    'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17',
    isProduct
  )

  return subgraphInstance
    .initSubgraph()
    .then((a: any) => a.getAllPools(false))
    .then((data: any) => {
      return data
    })
    .catch((error: Error) => {
      console.log('error getAllPools', error)
    })
}

export const getPoolAprs = async () => {
  const subgraphInstance = new Subgraph(
    'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17',
    isProduct
  )

  return subgraphInstance
    .initSubgraph()
    .then((a: any) => a.getPoolAprs(false))
    .then((data: any) => {
      return data
    })
    .catch((error: Error) => {
      console.log('error getPoolAprs', error)
    })
}

export const getTvlInUfoPools = async () => {
  const subgraphInstance = new Subgraph(
    'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17',
    isProduct
  )

  return subgraphInstance
    .initSubgraph()
    .then((a: any) => a.getTvlInUfoPoolsInDollars(false))
    .then((data: any) => {
      return data
    })
    .catch((error: Error) => {
      console.log('error getTvlInUfoPools', error)
    })
}

export const getStakingOverView = async () => {
  const subgraphInstance = new Subgraph(
    'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17',
    isProduct
  )

  return subgraphInstance
    .initSubgraph()
    .then((a: any) => a.getStakingOverView(false))
    .then((data: any) => {
      return data
    })
    .catch((error: Error) => {
      console.log('error getTvlInUfoPools', error)
    })
}

export const getTotalTvlInUfoDollars = async () => {
  const subgraphInstance = new Subgraph(
    'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17',
    isProduct
  )

  return subgraphInstance
    .initSubgraph()
    .then((a: any) => a.getTvlInUfoPoolsInDollars())
    .then((data: any) => {
      return data
    })
    .catch((error: Error) => {
      console.log('error getTvlInUfoPools', error)
    })
}
export const getTotalTvlInLPDollars = async () => {
  const subgraphInstance = new Subgraph(
    'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17',
    isProduct
  )

  return subgraphInstance
    .initSubgraph()
    .then((a: any) => a.getTvlInLpPoolsInDollars())
    .then((data: any) => {
      return data
    })
    .catch((error: Error) => {
      console.log('error getTvlInUfoPools', error)
    })
}

export const getTvlInLpPools = async () => {
  const subgraphInstance = new Subgraph(
    'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17',
    isProduct
  )

  return subgraphInstance
    .initSubgraph()
    .then((a: any) => a.getTvlInLpPoolsInDollars(false))
    .then((data: any) => {
      return data
    })
    .catch((error: Error) => {
      console.log('error getTvlInLpPools', error)
    })
}

export const getAllDepositsWithUfoRewardPending = async (address) => {
  const subGraph = await subgraphInstance.initSubgraph()
  const data = subGraph.getAllDepositsWithUfoRewardPending(address, false)
  return data
}

export const getStackedWithdrawnFlexiUfoDepositsOfUser = async (address) => {
  const subGraph = await subgraphInstance.initSubgraph()
  const data = subGraph.getStackedWithdrawnFlexiUfoDepositsOfUser(
    address,
    false
  )
  return data
}

export const getStackedWithdrawnFlexiLpDepositsOfUser = async (address) => {
  const subGraph = await subgraphInstance.initSubgraph()
  const data = subGraph.getStackedWithdrawnFlexiLpDepositsOfUser(address, false)
  return data
}

export const getUserInfo = async (address: string): Promise<any> => {
  await subgraphInstance.initSubgraph()
  const userInfo = await subgraphInstance.getUserInfo(address, false) // pass any users wallet here
  return userInfo
}

export const getStackedFlexiUfoDepositOfUser = async (address) => {
  const subgraphInstance = new Subgraph(
    'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17',
    isProduct
  )

  return subgraphInstance
    .initSubgraph()
    .then((a: any) => a.getStackedFlexiUfoDepositOfUser(address, false))
    .then((data: any) => {
      return data
    })
    .catch((error: Error) => {
      console.log('error getStackedFlexiUfoDepositOfUser', error)
    })
}

export const getStackedFlexiLpDepositOfUser = async (address) => {
  const subgraphInstance = new Subgraph(
    'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17',
    isProduct
  )
  return subgraphInstance
    .initSubgraph()
    .then((a: any) => a.getStackedFlexiLpDepositOfUser(address, false))
    .then((data: any) => {
      return data
    })
    .catch((error: Error) => {
      console.log('error getStackedFlexiLpDepositOfUser', error)
    })
}

interface IMyStakes {
  amount: BigNumber
  poolDetails: {
    poolType: string
    poolLockType: string
  }
  vestedReward: BigNumber
}

export const getMyStakes = async (address) => {
  const subgraphUrl =
    'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17'

  const subgraphInstance = new Subgraph(subgraphUrl, isProduct)
  const data = await subgraphInstance.getAllUserDeposits(
    '0x860Fc09EACd32b3cBE00d56163D540Ed65557cCC',
    false
  )
  const deposited = data.filter((item) => item.state === 'DEPOSITED')
  const filterFlexible = deposited.filter(
    (item) => item.poolDetails.poolLockType !== 'FLEXI'
  )
  return filterFlexible
}

export const getPoolWeights = async () => {
  const subgraphInstance = new Subgraph(
    'https://gateway.thegraph.com/api/afb1023a6969388004b10016bc98f249/subgraphs/id/q3Uke8C9XxpgEfQpnke8ySbrtcJDeKF9UpbpoEeZi17',
    isProduct
  )

  return subgraphInstance
    .initSubgraph()
    .then((a: any) => a.getAllPoolWeights())
    .then((data: any) => {
      return data
    })
    .catch((error: Error) => {
      console.log('error getAllPoolWeights', error)
    })
}
