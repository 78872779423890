import { Flex } from '@chakra-ui/react'
import { FC } from 'react'

import { ModalPopup } from '@/components/atoms'
import { VideoPlayer } from '@/components/molecules/VideoPlayer/index'

interface iHowToStakeVideoModalProps {
  isOpen: boolean
  onClose: () => void
}

const HowToStakeVideoModal: FC<iHowToStakeVideoModalProps> = (props) => {
  const { isOpen, onClose } = props

  return (
    <ModalPopup
      title="How to Stake?"
      isOpen={isOpen}
      onCloseModal={onClose}
      sx={{
        maxWidth: '1000px',
        minWidth: '400px',
        width: '80vw',
        marginTop: '-50px',
      }}
    >
      <Flex direction="column" paddingBottom="16px">
        <VideoPlayer loop radius="20px" controls />
      </Flex>
    </ModalPopup>
  )
}

export default HowToStakeVideoModal
