import { ethers } from 'ethers'
import { Staking_SDK } from 'ufo-sdk'

import {
  UFO_TOKEN_CONTRACT_ADDRESS,
  UFOETHLP_TOKEN_CONTRACT_ADDRESS,
  PLASMA_TOKEN_CONTRACT_ADDRESS,
} from '../../../utils/constants'

import { contracts } from '@/App'

export async function getUFOBalance(
  signer,
  networkId,
  address
): Promise<string> {
  try {
    const instance = new Staking_SDK(signer, contracts)
    return instance
      .getUfoTokenBalance(address, false, networkId, UFO_TOKEN_CONTRACT_ADDRESS)
      .then((data: string) => {
        return data
      })
  } catch (error) {
    return '0'
  }
}

export async function getLPBalance(
  signer,
  networkId,
  address
): Promise<string> {
  try {
    const instance = new Staking_SDK(signer, contracts) // this is only for inline-demo
    return instance
      .getLpTokenBalance(
        address,
        false,
        networkId,
        UFOETHLP_TOKEN_CONTRACT_ADDRESS
      )
      .then((data: string) => {
        return data
      })
  } catch (error) {
    return '0'
  }
}
export async function getPlasmaBalance(signer, networkId, address) {
  try {
    const instance = new Staking_SDK(signer, contracts) // this is only for inline-demo
    return instance
      .getPlasmaTokenBalance(
        address,
        false,
        networkId,
        PLASMA_TOKEN_CONTRACT_ADDRESS
      )
      .then((data: string) => {
        return data
      })
  } catch (error) {
    return '0'
  }
}
