import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { useEffect, useState, useRef } from 'react'
import NumberFormat from 'react-number-format'

import {
  getTvlInLpPools,
  getTvlInUfoPools,
  getTotalTvlInUfoDollars,
  getTotalTvlInLPDollars,
  getStakingOverView,
} from '../pages/Markets/service'

import Loader from '@/components/Loader'

interface iProps {
  stakData: string[]
}

const StakedValues = (props: iProps): JSX.Element => {
  const { stakData } = props
  const headerLoader = useRef<any>(null)

  const [allValues, setAllValues] = useState({
    totalTVL: '',
    totalAPR: '',
    ufoTotalPool: '',
    ufoLPTotalPool: '',
  })

  useEffect(() => {
    const request = new Promise((res, rej) => {
      headerLoader.current && headerLoader?.current?.continuousStart()
      const stakedOverview = getStakingOverView()

      if (stakedOverview) {
        res(stakedOverview)
      } else {
        rej(new Error('Bad request'))
      }
    })

    request.then((stakedOverview: any) => {
      const totalUfoStaked = stakedOverview.totalUfoStaked
      const totalLpStaked = stakedOverview.totalLpStaked
      const totalTVL = stakedOverview.currentAvgTvl
      const totalAPR = stakedOverview.currentAvgApr

      setAllValues({
        ...allValues,
        totalTVL: `${Math.round(totalTVL)}`,
        totalAPR,
        ufoTotalPool: totalUfoStaked === 'NaN' ? '0' : totalUfoStaked,
        ufoLPTotalPool: totalLpStaked === 'NaN' ? '0' : totalLpStaked,
      })
    })

    return () => {
      setAllValues({
        totalTVL: '',
        totalAPR: '',
        ufoTotalPool: '',
        ufoLPTotalPool: '',
      }) // This worked for me
    }
  }, [])

  return (
    <Flex justifyContent="space-between" display="flex" flexWrap="wrap">
      {allValues.totalTVL &&
      allValues.totalAPR &&
      allValues.ufoTotalPool &&
      allValues.ufoLPTotalPool ? (
        <>
          <Box sx={{ gap: '48px' }}>
            <Heading
              variant="h2"
              as="h2"
              fontSize={['16px', '25px', '32px']}
              color="main.spaceGrey"
              sx={{
                marginBottom: '15px',
              }}
            >
              <NumberFormat
                value={allValues.totalTVL}
                displayType="text"
                thousandSeparator
                prefix="$"
                renderText={(value) => <>{value}</>}
              />
            </Heading>
            <Text fontSize="16px">Current TVL</Text>
          </Box>
          <Box>
            <Heading
              variant="h2"
              as="h2"
              fontSize={['16px', '25px', '32px']}
              color="main.spaceGrey"
              textAlign="end"
              sx={{
                marginBottom: '15px',
              }}
            >
              <NumberFormat
                value={Number(allValues.totalAPR).toFixed(2)}
                displayType="text"
                thousandSeparator
                renderText={(value) => <>{`${value}%`}</>}
              />
            </Heading>
            <Text fontSize="16px">Current avg. APR</Text>
          </Box>
          <Box sx={{ margin: '0 0 48px 0' }}>
            <Heading
              variant="h2"
              as="h2"
              fontSize={['16px', '25px', '32px']}
              color="main.spaceGrey"
              sx={{
                marginBottom: '15px',
              }}
            >
              <NumberFormat
                value={Number(allValues.ufoTotalPool).toFixed(0)}
                displayType="text"
                thousandSeparator
                renderText={(value) => <>{value}</>}
              />
            </Heading>
            <Text fontSize="16px">Total UFO Staked</Text>
          </Box>
          <Box>
            <Heading
              variant="h2"
              as="h2"
              fontSize={['16px', '25px', '32px']}
              color="main.spaceGrey"
              textAlign="end"
              sx={{
                marginBottom: '15px',
              }}
            >
              <NumberFormat
                value={Number(allValues.ufoLPTotalPool).toFixed(0)}
                displayType="text"
                thousandSeparator
                renderText={(value) => <>{value}</>}
              />
            </Heading>
            <Text fontSize="16px" maxWidth={['140px', '100%', '100%']}>
              Total UFO-ETH LP Staked
            </Text>
          </Box>
        </>
      ) : (
        <Loader ref={headerLoader} />
      )}
    </Flex>
  )
}

export default StakedValues
