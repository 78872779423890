import { Text, Flex, Box, Button, useMediaQuery } from '@chakra-ui/react'
import { FC } from 'react'

interface iCookieAgreementProps {
  onCookieAgreement: (string: string) => void
}

export const CookieAgreementPopup: FC<iCookieAgreementProps> = ({
  onCookieAgreement,
}) => {
  const [isSmallerThan650] = useMediaQuery('(max-width: 650px)')

  return (
    <Box display="flex" justifyContent="center">
      <Flex
        background="rgba(0, 0, 0, 0.73)"
        border="1px solid rgba(255, 255, 255, 0.08)"
        borderRadius="8px 8px 0px 0px"
        padding="15px 24px"
        zIndex="9999"
        position="fixed"
        bottom="0"
        justifyContent="space-between"
        backdropFilter="blur(20px)"
        minWidth="320px"
        maxWidth="1170px"
        margin="0 auto"
        direction={isSmallerThan650 ? 'column' : 'row'}
        gap="16px"
      >
        <Flex direction="column" color="#ffffff" mr="24px">
          <Text fontSize="13px" fontWeight="700" lineHeight="1" mb="4px">
            This website uses cookies
          </Text>
          <Text fontSize="12px" fontWeight="400" lineHeight="1.4">
            We use cookies to improve your experience by providing insights into
            how our site is being used,{' '}
            <a
              href="https://ufogaming.io/privacy-policy"
              target="_blank"
              style={{ color: '#D2FF59', borderBottom: '2px solid #D2FF59' }}
            >
              click here
            </a>{' '}
            to learn more.
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Button
            borderBottom="2px solid #D2FF59"
            padding="0 0 10px 0"
            background="transparent"
            borderRadius="0px"
            color="#D2FF59"
            height="auto"
            fontSize="14px"
            fontWeight="700"
            _hover={{ opacity: 0.8 }}
            onClick={() => onCookieAgreement('denied')}
          >
            Deny
          </Button>
          <Button
            borderBottom="2px solid #D2FF59"
            padding="0 0 10px 0"
            background="transparent"
            color="#D2FF59"
            height="auto"
            borderRadius="0px"
            ml="10px"
            fontSize="14px"
            fontWeight="700"
            _hover={{ opacity: 0.8 }}
            onClick={() => onCookieAgreement('accepted')}
          >
            Approve
          </Button>
        </Flex>
      </Flex>
    </Box>
  )
}
