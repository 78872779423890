import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Heading,
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'

import { GetFAQ } from '../../services/index'

interface iItem {
  id?: number
  attributes?: {
    title?: string
    description?: string
  }
}

const AccordionContent = (): JSX.Element => {
  const [data, setData] = useState<iItem[]>([])

  useEffect(() => {
    async function anyNameFunction() {
      setData(await GetFAQ())
    }
    anyNameFunction()
  }, [])

  return (
    <>
      {data ? (
        data.map((item: iItem, index) => {
          return (
            <Accordion allowToggle key={index}>
              <AccordionItem
                sx={{
                  border: '1px solid #000',
                  padding: '20px',
                  marginBottom: '10px',
                }}
              >
                <AccordionButton>
                  <Box
                    flex="1"
                    textAlign="left"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <AccordionIcon marginRight="15px" />
                    <Heading
                      variant="h2"
                      as="h3"
                      fontSize="18px"
                      color="main.spaceGrey"
                    >
                      {item.attributes?.title}
                    </Heading>
                  </Box>
                </AccordionButton>
                <AccordionPanel fontSize="16px">
                  {item.attributes?.description}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )
        })
      ) : (
        <div />
      )}
    </>
  )
}

export default AccordionContent
