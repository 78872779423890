import { Button ,
  Box,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  VStack,
  Container,
  Center,
  Link,
  useMediaQuery } from '@chakra-ui/react'

import { FC, useEffect, useState, useMemo, useRef } from 'react'
import { ReactComponent as Polygon } from '@/assets/img/polygon.svg'

import { PlasmaResetTimer } from './PlasmaResetTimer'
import { PoolSummary } from './PoolSummary'
import { StakeAction } from './StakeAction'
import { StakeModal } from './StakeModal'
import { NetworkModal } from '@/components/pages/Markets/NetworkModal'
import { StakesSummary } from './StakeSummary'

import { BrandedBox } from '@/components'
import {
  UFOETHLP_STAKING_CONTRACT_ADDRESS,
  UFO_STAKING_CONTRACT_ADDRESS,
} from '@/utils/constants'
import { useWallet } from '@/web3/WalletContext'

import { StakingStartView } from '@/components/startScreen'

import PageSmallBall from '@/components/pageBall/smallBall'
import {
  getTvlInUfoPools,
  getTvlInLpPools,
  getUserInfo,
  getAllDepositsWithUfoRewardPending,
  getStackedWithdrawnFlexiUfoDepositsOfUser,
  getStackedWithdrawnFlexiLpDepositsOfUser,
} from '@/components/pages/Markets/service'
import NeedSomeUfpPage from '@/components/NeedSomeUFO'
import UnclaimedRewards from '@/components/UnclaimedRewards'
import StakesPage from '@/components/Stakes'
import MarqueeComponent from '@/components/Mark'
import { useCGPrice } from '@/web3/hooks'

import PendingUnlock from '../../PendingUnlock'
import { CollectFunction } from './CollectFunction'
import { MultipleCollect } from './MultipleCollect'

import ButtonBase from '@/components/button'
import { ReactComponent as UfoIcon } from '@/assets/img/UFO_icon.svg'

import { changeNetwork } from '../Markets/NetworkModal'

import { getLocality } from '@/utils/getLocality'
import { getFormatedDate } from '@/utils/getFormatedDate'

import Loader from '@/components/Loader'

import { Leaderboard } from './Leaderboard'

import { ConnectButton } from '@rainbow-me/rainbowkit'

export const ethChainsIDs = {
  Ethereum: `0x${Number(1).toString(16)}`,
}

export const polygonChainsIDs = {
  Polygon: `0x${Number(137).toString(16)}`,
}

const chainsIDs = {
  ...polygonChainsIDs,
  ...ethChainsIDs,
}

export const supportNetworksIDs = Object.values(chainsIDs)
export const supportNetworksIDsArray = Object.entries(chainsIDs)
export const mainNetSupportIDsNetworks = Object.values({
  Polygon: `0x${Number(137).toString(16)}`,
  Ethereum: `0x${Number(1).toString(16)}`,
})

export const mainNetSupportIDsNetworksWithName = Object.entries({
  Polygon: `0x${Number(137).toString(16)}`,
  Ethereum: `0x${Number(1).toString(16)}`,
})

export const testNetSupportIDsNetworks = Object.values({
  Mumbai: `0x${Number(80001).toString(16)}`,
  Rinkeby: `0x${Number(4).toString(16)}`,
  Ropsten: `0x${Number(3).toString(16)}`,
  Kovan: `0x${Number(42).toString(16)}`,
  Goerli: `0x${Number(5).toString(16)}`,
  Mordor: `0x${Number(63).toString(16)}`,
  Kotti: `0x${Number(6).toString(16)}`,
  Astor: `0x${Number(212).toString(16)}`,
})

const fetchPendingDeposits = async (address: any, loader?: any) => {
  const deps = await getAllDepositsWithUfoRewardPending(address)
  const flexiUfo = await getStackedWithdrawnFlexiUfoDepositsOfUser(address)
  const flexiLP = await getStackedWithdrawnFlexiLpDepositsOfUser(address)
  const pendingLocksExceptFlex = deps.filter(
    (item) => item.poolDetails.poolLockType != 'FLEXI'
  )
  if (flexiUfo) {
    for (let i = 0; i < flexiUfo.length; i++) {
      if (flexiUfo[i]) {
        pendingLocksExceptFlex.push(flexiUfo[i])
      }
    }
  }
  if (flexiLP) {
    for (let i = 0; i < flexiLP.length; i++) {
      if (flexiLP[i]) {
        pendingLocksExceptFlex.push(flexiLP[i])
      }
    }
  }

  return pendingLocksExceptFlex
}

const clearNaN = (data) => {
  const dataCopy = { ...data }
  Object.keys(dataCopy).forEach((key) => {
    if (dataCopy[key] === 'NaN' || dataCopy[key] === 'Infinity') {
      dataCopy[key] = '0'
    }
  })
  return dataCopy
}
const stakeName = [
  { name: 'All', value: '' },
  { name: 'UFO', value: 'UFO_POOL' },
  { name: 'UFO-ETH LP', value: 'LP_POOL' },
]

export const Markets: FC = () => {
  const { isConnected, provider, address, hexChainId: networkId } = useWallet()
  const [deposits, setDeposits] = useState<any[] | null>(null)
  const [filteredDeposits, setFilteredDeposits] = useState<any[] | null>(null)
  const tvlUFOLoader = useRef<any | null>(null)
  const [poolState, setPoolState] = useState<boolean | null>(true)
  const tvlLPLoader = useRef<any | null>(null)
  const pendingLoader = useRef<any | null>(null)
  const [allValues, setAllValues] = useState({
    ufoTotalPool: '',
    ufoLPTotalPool: '',
    userUfoShare: '',
    userLpShare: '',
    tvlUFO: '',
    tvlLP: '',
    ufoAPR: '',
    lpAPR: '',
    totalUfoStakedInDollars: '',
    totalLpStakedInDollars: '',
    ufoTokenRewardAvailable: '',
    plasmaRewardAvailable: '',
    ufoTokenRewardAvailableInDollar: '',
  })
  let ufoPrice = useCGPrice({ tokenId: 'ufo-gaming' })

  useEffect(() => {
    const combineDataFetcher = async (address: any) => {
      tvlUFOLoader.current && tvlUFOLoader?.current?.continuousStart()
      const userInfo = await getUserInfo(address)
      tvlLPLoader.current && tvlLPLoader?.current?.continuousStart()
      tvlUFOLoader.current && tvlUFOLoader?.current?.continuousStart()
      const ufoTVL = await getTvlInUfoPools()
      tvlLPLoader.current && tvlLPLoader?.current?.continuousStart()
      const lpTVL = await getTvlInLpPools()
      const clearedUserInfo = clearNaN(userInfo)

      setAllValues({
        ...allValues,
        ufoTotalPool: ufoTVL,
        ufoLPTotalPool: lpTVL,
        tvlUFO: ufoTVL,
        tvlLP: lpTVL,
        userUfoShare: clearedUserInfo?.myUfoShare,
        userLpShare: clearedUserInfo?.myLpShare,
        ufoAPR: clearedUserInfo?.ufoApr,
        lpAPR: clearedUserInfo?.lpApr,
        totalLpStakedInDollars: clearedUserInfo?.totalLpStakedInDollars,
        totalUfoStakedInDollars: clearedUserInfo?.totalUfoStakedInDollars,
        ufoTokenRewardAvailable: clearedUserInfo?.ufoTokenRewardAvailable,
        plasmaRewardAvailable: clearedUserInfo?.plasmaRewardAvailable,
        ufoTokenRewardAvailableInDollar:
          clearedUserInfo?.ufoTokenRewardAvailableInDollar,
      })

      setPoolState(false)
    }
    const pendingDataFetcher = async () => {
      await fetchPendingDeposits(address).then((data) => {
        setDeposits(data)
      })
    }
    if (isConnected) {
      combineDataFetcher(address)
      pendingDataFetcher()
    }
  }, [isConnected])

  const [stakeModal, setStakeModal] = useState(false)
  const [networkModal, setNetworkModal] = useState(false)
  const [modalType, setModalType] = useState(0)
  const [networkModalType, setNetworkModalType] = useState<any>(null)

  const handleChangeNetworkModalType = (name) => {
    setNetworkModalType(null)

    if (name === 'eth') {
      setNetworkModalType('eth')
    }
  }
  const [isLargerThan850] = useMediaQuery('(max-width: 850px)')
  const NoWalletConnected = () => (
    <BrandedBox flex="1" px="8" py="6">
      <VStack align="center" justifyContent="flex-start" spacing="4">
        <Heading as="h4" variant="h4" pb="6">
          MY STAKES
        </Heading>

        <Heading as="h4" variant="h4" fontSize="18px">
          Looks like your wallet’s not connected.
        </Heading>
        <Box pb="6" pt="4">
          <Text variant="body2">
            You need an Ethereum Wallet to interact with Super Galactic.
          </Text>
        </Box>
        <VStack>
          <ConnectButton />
          <Text variant="subtitle1">What’s a wallet?</Text>
        </VStack>
      </VStack>
    </BrandedBox>
  )

  const [name, setName] = useState<string>('All')

  const [isLargerThan992] = useMediaQuery('(max-width: 992px)')

  // useEffect(() => {
  //   if (address != undefined) {
  //     fetchPendingDeposits(address).then((data) => {
  //       setDeposits(data)
  //     })
  //   }

  //   const intervalDeposits = setInterval(async () => {
  //     if (address != undefined) {
  //       await fetchPendingDeposits(address).then((data) => {
  //         setDeposits(data)
  //       })
  //     }
  //   }, 15000)
  //   return () => {
  //     clearInterval(intervalDeposits)
  //   }
  // }, [])

  useEffect(() => {
    if (deposits) {
      if (name === 'All') {
        setFilteredDeposits(deposits)
      } else {
        const poolType = stakeName.find((item) => item.name === name)
        const dataByPool = deposits?.filter(
          (item) => item.poolDetails.poolType === poolType?.value
        )
        setFilteredDeposits(dataByPool)
      }
    }
  }, [name, deposits])

  useEffect(() => {
    pendingLoader?.current?.continuousStart()
  }, [pendingLoader.current])

  const handleNetworkSwitch = async (networkName) => {
    await changeNetwork({ networkName })
  }

  return (
    <>
      {isConnected ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {mainNetSupportIDsNetworks.includes(networkId) ? (
            <Box>
              <Container maxW="container.xl">
                <Stack w="full">
                  <Box
                    position="relative"
                    marginTop="80px"
                    display="flex"
                    alignItems="center"
                  >
                    <Flex align="center" justify="space-between" mt="6" mb="6">
                      <Heading variant="h5" as="h3" size="lg">
                        Pools
                      </Heading>
                      {/* <PlasmaResetTimer /> */}
                    </Flex>

                    <Box position="absolute" zIndex="-1" top="50px">
                      <PageSmallBall />
                    </Box>
                  </Box>
                  <Flex display="flex" flexDirection="column">
                    <Box
                      border="none"
                      borderBottom="1px solid #111"
                      width="100%"
                     />
                    {!isLargerThan992 && (
                      <Flex
                        fontWeight="400"
                        fontSize="10px"
                        lineHeight="19px"
                        width="61%"
                        gap="5%"
                        justifyContent="space-between"
                        pl="8px"
                        m="10px 0 10px 30px"
                      >
                        <Box
                          fontStyle="normal"
                          fontWeight="700"
                          lineHeight="19px"
                          color="#102017"
                          fontSize="12px"
                        >
                          Pool
                        </Box>
                        <Box
                          fontStyle="normal"
                          fontWeight="700"
                          lineHeight="19px"
                          color="#102017"
                          fontSize="12px"
                        >
                          TVL
                        </Box>
                        <Box
                          fontStyle="normal"
                          fontWeight="700"
                          lineHeight="19px"
                          color="#102017"
                          fontSize="12px"
                        >
                          My Share
                        </Box>
                        <Box
                          fontStyle="normal"
                          fontWeight="700"
                          lineHeight="19px"
                          color="#102017"
                          fontSize="12px"
                        >
                          APR
                        </Box>
                      </Flex>
                    )}

                    {!poolState ? (
                      <PoolSummary
                        contract={UFO_STAKING_CONTRACT_ADDRESS}
                        name="UFO"
                        ufoTotalPool={allValues.ufoTotalPool}
                        tvl={Number(allValues.tvlUFO).toFixed(0)}
                        userShare={
                          Number(allValues.userUfoShare) > 1
                            ? Number(allValues.userUfoShare).toFixed(2)
                            : Number(allValues.userUfoShare).toFixed(5)
                        }
                        userShareInDollar={Number(
                          allValues.totalUfoStakedInDollars
                        ).toFixed(2)}
                        APR={`${Number(allValues.ufoAPR).toFixed(2)}%`}
                        title="UFO"
                        btnTitle="Buy UFO"
                        link="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x249e38ea4102d0cf8264d3701f1a0e39c4f2dc3b&chain=mainnet"
                        modalType={1}
                        setModalType={setModalType}
                        setStakeModal={
                          Object.values(polygonChainsIDs).includes(networkId)
                            ? setStakeModal
                            : setNetworkModal
                        }
                        setNetworkModal={setNetworkModal}
                        setNetworkModalType={handleChangeNetworkModalType}
                      />
                    ) : (
                      <Box sx={{ marginBottom: 15 }}>
                        <Loader ref={tvlUFOLoader} />
                      </Box>
                    )}

                    {!poolState ? (
                      <PoolSummary
                        contract={UFOETHLP_STAKING_CONTRACT_ADDRESS}
                        name="UFO-ETH LP"
                        ufoTotalPool={allValues.ufoLPTotalPool}
                        tvl={Number(allValues.tvlLP).toFixed(0)}
                        userShare={
                          Number(allValues.userLpShare) > 1
                            ? Number(allValues.userLpShare).toFixed(2)
                            : Number(allValues.userLpShare).toFixed(5)
                        }
                        userShareInDollar={Number(
                          allValues.totalLpStakedInDollars
                        ).toFixed(2)}
                        APR={`${Number(allValues.lpAPR).toFixed(2)}%`}
                        title="UFO-ETH LP"
                        btnTitle="Buy LP"
                        link="https://app.uniswap.org/#/add/v2/ETH/0x249e38Ea4102D0cf8264d3701f1a0E39C4f2DC3B?chain=mainnet"
                        modalType={2}
                        setModalType={setModalType}
                        setStakeModal={
                          Object.values(polygonChainsIDs).includes(networkId)
                            ? setStakeModal
                            : setNetworkModal
                        }
                        setNetworkModal={setNetworkModal}
                        setNetworkModalType={handleChangeNetworkModalType}
                      />
                    ) : (
                      <Loader ref={tvlLPLoader} />
                    )}
                  </Flex>
                  <StakesSummary
                    stakeModal={
                      Object.values(polygonChainsIDs).includes(networkId)
                        ? stakeModal
                        : networkModal
                    }
                    setStakeModal={
                      Object.values(polygonChainsIDs).includes(networkId)
                        ? setStakeModal
                        : setNetworkModal
                    }
                    setModalType={setModalType}
                  />
                  <NeedSomeUfpPage
                    setNetworkModal={setNetworkModal}
                    setNetworkModalType={handleChangeNetworkModalType}
                  />
                  <StakeModal
                    isOpen={stakeModal}
                    modalType={modalType}
                    setModalType={setModalType}
                    onCloseModal={() => setStakeModal(false)}
                  />
                  <NetworkModal
                    isOpen={networkModal}
                    onCloseModal={() => {
                      setNetworkModalType(null)
                      setNetworkModal(false)
                    }}
                    networkModalType={networkModalType}
                  />
                  <Box>
                    <Box
                      position="relative"
                      marginTop="80px"
                      display="flex"
                      alignItems="center"
                    >
                      <Flex
                        align="center"
                        justify="space-between"
                        mt="6"
                        mb="6"
                        id="rewards"
                      >
                        <Heading variant="h5" as="h3" size="lg">
                          Rewards
                        </Heading>
                      </Flex>

                      <Box position="absolute" zIndex="-1" top="60px">
                        <PageSmallBall />
                      </Box>
                    </Box>
                  </Box>
                  <UnclaimedRewards
                    plasmaRewardAvailable={allValues?.plasmaRewardAvailable}
                    ufoTokenRewardAvailable={allValues?.ufoTokenRewardAvailable}
                    ufoTokenRewardAvailableInDollar={
                      allValues?.ufoTokenRewardAvailableInDollar
                    }
                    setStakeModal={
                      Object.values(polygonChainsIDs).includes(networkId)
                        ? setStakeModal
                        : setNetworkModal
                    }
                    setModalType={setModalType}
                  />
                  <Box id="pendingUnlock">
                    <Box marginTop="50px">
                      <Flex justifyContent="space-between" pb="20px">
                        <Heading variant="h5" as="h3" size="lg">
                          Pending Unlock
                        </Heading>
                        {!!(Array.isArray(deposits) && deposits.length) && (
                          <Box
                            gap="20px"
                            display={{ base: 'none', md: 'flex' }}
                          >
                            {stakeName.map((val, index) => {
                              return (
                                <Text
                                  key={index}
                                  fontWeight="700"
                                  fontSize="16px"
                                  lineHeight="21px"
                                  cursor="pointer"
                                  color={
                                    val.value == name ? '#102017' : '#A65AF6'
                                  }
                                  onClick={() => setName(val.name)}
                                >
                                  {val.name}
                                </Text>
                              )
                            })}
                          </Box>
                        )}
                      </Flex>
                      <Box border=" 1px solid #102017" mb="24px" />
                      {Array.isArray(deposits) ? null : (
                        <Loader ref={pendingLoader} />
                      )}
                      {Array.isArray(filteredDeposits) &&
                      filteredDeposits.length ? (
                        <>
                          <Flex
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="19px"
                            ml="30px"
                            // mt="10px"
                            mb="7px"
                            w="88%"
                            display={{ base: 'none', md: 'flex' }}
                          >
                            <Box w="30%">Pool</Box>
                            <Box w="30%">Rewards Amount(UFO)</Box>
                            <Box w="30%">USD Value</Box>
                            <Box w="30%">Payout Date</Box>
                          </Flex>

                          <Flex flexDirection="column" gap="20px">
                            {filteredDeposits.map((val, idx) => {
                              const date = getFormatedDate(
                                val.vestedRewardUnlocksAt
                              )
                              return (
                                <PendingUnlock
                                  key={String(idx) + 'pendingUnlock'}
                                  pool={val.poolDetails.poolType?.replace(
                                    '_POOL',
                                    ''
                                  )}
                                  rewardsAmount={Number(
                                    val.vestedReward
                                  ).toFixed(2)}
                                  USDValue={Number(
                                    val.usdValueOfVestedReward
                                  ).toFixed(2)}
                                  payoutDate={date}
                                  isVestedRewardClaimable={
                                    val.isVestedRewardClaimable
                                  }
                                  onCollect={() => {
                                    if (val.depositNumber) {
                                      CollectFunction(
                                        val.poolDetails.lockInWeeks,
                                        val.depositNumber,
                                        val.poolDetails.poolType?.replace(
                                          '_POOL',
                                          ''
                                        )
                                      )
                                    }
                                    if (val.depositNumbers) {
                                      MultipleCollect(
                                        val.poolDetails.lockInWeeks,
                                        val.depositNumbers,
                                        val.poolDetails.poolType?.replace(
                                          '_POOL',
                                          ''
                                        )
                                      )
                                    }
                                  }}
                                />
                              )
                            })}
                          </Flex>
                        </>
                      ) : (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          flexWrap="wrap"
                        >
                          {Array.isArray(filteredDeposits) &&
                          !filteredDeposits.length ? (
                            <Box>
                              <Heading fontSize="18px" fontWeight="800">
                                You have no pending unlocks.
                              </Heading>
                            </Box>
                          ) : null}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Leaderboard />
                </Stack>
              </Container>
            </Box>
          ) : (
            <Container maxW="container.xl">
              <Box w="full" sx={{ marginTop: '30px' }}>
                <Box
                  sx={{
                    background: '#102017',
                    borderRadius: '27px',
                    padding: '40px',

                    '@media screen and (max-width: 410px)': {
                      padding: '20px',
                    },
                  }}
                >
                  <Heading
                    sx={{
                      color: '#fff',
                      fontSize: '24px',
                      lineHeight: '1.4',

                      '@media screen and (max-width: 400px)': {
                        fontSize: '16px',
                      },
                    }}
                    as="p"
                    size="lg"
                    marginBottom="32px"
                    fontWeight="700"
                  >
                    You are currently connected to non-supported network,
                    whereas our staking dApp runs on Polygon. Please bridge your
                    tokens to Polygon and then switch networks to Polygon.
                  </Heading>

                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '10px',
                    }}
                  >
                    <Link
                      href="https://wallet.polygon.technology/polygon/bridge/deposit"
                      isExternal
                      sx={{
                        textDecoration: 'none !important',

                        '@media screen and (max-width: 400px)': {
                          width: '100%',
                        },
                      }}
                    >
                      <ButtonBase
                        height="62px"
                        position="relative"
                        background="linear-gradient(180deg, #FA937D 0%, #8924F4 100%)"
                        color="main.orange"
                        justifyContent="center"
                        p="2px"
                        overflow="hidden"
                        outline="none"
                        fontStyle="normal"
                        _hover="none"
                        sx={{
                          minWidth: '333px',
                          marginRight: '20px',

                          '@media screen and (max-width: 400px)': {
                            width: '100%',
                            minWidth: '100%',
                          },
                        }}
                      >
                        <Box
                          w="100%"
                          h="100%"
                          background="#102017"
                          borderRadius="50px"
                          fontStyle="normal"
                          fontWeight="700"
                          fontSize="14px"
                          lineHeight="20px"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0 2px 0 32px',
                            fontSize: '16px',

                            '@media screen and (max-width: 410px)': {
                              fontSize: '10px',
                            },
                          }}
                        >
                          Bridge Tokens to Polygon
                          <Box sx={{ marginLeft: 'auto' }}>
                            <Polygon width={56} height={56} />
                          </Box>
                        </Box>
                      </ButtonBase>
                    </Link>

                    {/* {provider?.provider?.isMetaMask && (
                      <ButtonBase
                        height="62px"
                        position="relative"
                        background={'main.orange'}
                        color="#fff"
                        justifyContent="center"
                        p="2px"
                        overflow="hidden"
                        outline="none"
                        fontStyle="normal"
                        _hover="none"
                        onClick={() => handleNetworkSwitch('polygon')}
                        sx={{
                          minWidth: '333px',
                          marginRight: '20px',

                          '@media screen and (max-width: 400px)': {
                            width: '100%',
                            minWidth: '100%',
                          },
                        }}
                      >
                        <Box
                          w="100%"
                          h="100%"
                          background="linear-gradient(180deg, #FA937D 0%, #8924F4 100%)"
                          borderRadius="50px"
                          fontStyle="normal"
                          fontWeight="700"
                          fontSize="14px"
                          lineHeight="20px"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0 2px 0 32px',
                            fontSize: '16px',

                            '@media screen and (max-width: 410px)': {
                              fontSize: '10px',
                            },
                          }}
                        >
                          Switch Network to Polygon
                          <Box sx={{ marginLeft: 'auto' }}>
                            <Polygon width={56} height={56} />
                          </Box>
                        </Box>
                      </ButtonBase>
                    )} */}
                  </Box>
                </Box>
              </Box>
            </Container>
          )}
          <MarqueeComponent text="About Us " />
        </Box>
      ) : (
        <StakingStartView />
      )}
    </>
  )
}
