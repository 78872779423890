import { Box, Flex, Text, Image, useMediaQuery } from '@chakra-ui/react'
import { FC } from 'react'
import NumberFormat from 'react-number-format'

import { ReactComponent as ArrowOnPurple } from '@/assets/img/arrowOnPurple.svg'
import ButtonBase from '@/components/button'

interface iMyStake {
  pool: string
  rewardsAmount: string
  USDValue: string
  payoutDate: string
  isVestedRewardClaimable: boolean
  onCollect: () => void
}

const PendingUnlock: FC<iMyStake> = (props) => {
  const {
    pool,
    rewardsAmount,
    USDValue,
    payoutDate,
    isVestedRewardClaimable,
    onCollect,
  } = props
  const [isSmallerThan768] = useMediaQuery('(max-width: 767px)')

  return (
    <Flex
      bg="#102017"
      borderRadius="27px"
      w="100%"
      p={{ base: '30px', md: '8px 8px 8px 30px' }}
      color="white"
      justifyContent="space-between"
      alignItems="center"
      flexDirection={{ base: 'column', md: 'row' }}
    >
      <Flex
        w="100%"
        ml="30px"
        flexDirection={{ base: 'column', sm: 'row' }}
        gap={{ base: '8px', sm: '0' }}
      >
        <Flex w="100%">
          <Box fontWeight="700" fontSize="14px" lineHeight="20px" w="50%">
            <Flex flexDirection={{ base: 'column', md: 'row' }}>
              <Box>
                <Text
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="19px"
                  display={{ base: 'inline', md: 'none' }}
                >
                  Pool
                </Text>
              </Box>
              <Box>
                <Text>{pool === 'LP' ? 'UFO-ETH LP' : pool}</Text>
              </Box>
            </Flex>
          </Box>
          <Box fontWeight="700" fontSize="14px" lineHeight="20px" w="50%">
            <Flex
              flexDirection={{ base: 'column', md: 'row' }}
              mr={{ base: '30px', md: 'none' }}
            >
              <Box>
                <Text
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="19px"
                  display={{ base: 'inline', md: 'none' }}
                  float={{ base: 'right', sm: 'none' }}
                >
                  Rewards Amount (UFO)
                </Text>
              </Box>
              <Box>
                <Text float={{ base: 'right', sm: 'none' }}>
                  <NumberFormat
                    value={rewardsAmount}
                    displayType="text"
                    thousandSeparator
                    renderText={(value) => <>{value}</>}
                  />
                </Text>
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Flex w="100%" flexDirection={{ base: 'row-reverse', md: 'row' }}>
          <Box fontWeight="700" fontSize="14px" lineHeight="20px" w="50%">
            <Flex
              flexDirection={{ base: 'column', md: 'row' }}
              mr={{ base: '30px', md: 'none' }}
            >
              <Box>
                <Text
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="19px"
                  display={{ base: 'inline', md: 'none' }}
                  float={{ base: 'right', sm: 'none' }}
                >
                  USD Value
                </Text>
              </Box>
              <Box>
                <Text float={{ base: 'right', sm: 'none' }}>{USDValue}</Text>
              </Box>
            </Flex>
          </Box>
          <Box fontWeight="700" fontSize="14px" lineHeight="20px" w="50%">
            <Flex flexDirection={{ base: 'column', md: 'row' }}>
              <Box>
                <Text
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="19px"
                  display={{ base: 'inline', md: 'none' }}
                >
                  Payout Date
                </Text>
              </Box>
              <Box>
                <Text>{payoutDate}</Text>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Flex gap="8px" w={isSmallerThan768 ? '100%' : 'none'}>
        <Box
          maxW={{ base: '100%', md: '50%' }}
          w={isSmallerThan768 ? '100%' : 'none'}
          mt={isSmallerThan768 ? '16px' : 'none'}
        >
          <ButtonBase
            minW="110px"
            w="100%"
            isDisabled={!isVestedRewardClaimable}
            isActive={!isVestedRewardClaimable}
            position="relative"
            fontWeight="400"
            lineHeight="20px"
            onClick={() => onCollect()}
          >
            <Text>Collect</Text>
            <ArrowOnPurple style={{ position: 'absolute', right: '4px' }} />
          </ButtonBase>
        </Box>
      </Flex>
    </Flex>
  )
}

export default PendingUnlock
