import React, { createContext, useContext } from 'react'

import { useLockedDeposits } from '@/hooks/useLockedDeposits'
import { Staking, Staking__factory } from '@/types/typechain'
import {
  UFOETHLP_STAKING_CONTRACT_ADDRESS,
  UFO_STAKING_CONTRACT_ADDRESS,
} from '@/utils/constants'
import { useContract } from '@/web3/hooks'

export type GlobalContextType = {
  ufoLockedDeposits: ReturnType<typeof useLockedDeposits>
  ufoEthLpLockedDeposits: ReturnType<typeof useLockedDeposits>
}

export const GlobalContext = createContext<GlobalContextType>({
  ufoLockedDeposits: [
    [],
    {
      refresh: () => {},
      loading: false,
    },
  ],
  ufoEthLpLockedDeposits: [
    [],
    {
      refresh: () => {},
      loading: false,
    },
  ],
})

export const GlobalProvider = ({ children }) => {
  const { contract: ufoContract } = useContract(
    UFO_STAKING_CONTRACT_ADDRESS,
    Staking__factory
  )
  const { contract: ufoEthLpContract } = useContract(
    UFOETHLP_STAKING_CONTRACT_ADDRESS,
    Staking__factory
  )

  const ufoLockedDeposits = useLockedDeposits(ufoContract as Staking)
  const ufoEthLpLockedDeposits = useLockedDeposits(ufoEthLpContract as Staking)

  return (
    <GlobalContext.Provider
      value={{
        ufoLockedDeposits,
        ufoEthLpLockedDeposits,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalState = (): GlobalContextType => useContext(GlobalContext)
