export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const SET_UFO_TOTAL_POOL = 'SET_UFO_TOTAL_POOL'
export const SET_LP_TOTAL_POOL = 'SET_LP_TOTAL_POOL'

export interface LanguageInitialState {
  selectedLanguage: {
    body: {
      now: string
      title: string
      Amount: string
      recent: string
      stakes: string
      min_ago: string
      subtitle: string
      desc: string
      sub_title_footer: string
      Stake_UFO: string
      staked_at: string
      current_TVL: string
      leaderboard: string
      video_title: string
      connect_wallet: string
      locked_staking: string
      wallet_address: string
      approximate_APR: string
      take_UFO_ETH_LP: string
      flexible_staking: string
      total_UFO_staked: string
      subtitle_description: string
      total_UFO_ETH_LP_staked: string
      faq: string
      coming_soon: string
    }
    footer: {
      about: string
      available_on: string
      dark_metaverse: string
      games: string
      get_UFO: string
      cariers: string
      info: string
      join_the_UFO_community: string
      links: string
      privacy_Policy: string
      staking: 'Staking'
      terms_and_conditions: string
      tournaments: string
      web_design_by: string
      charities: string
    }
    header: {
      leaderboard: string
      FAQ: string
      how_to_stake: string
      language: string
    }
  }
}

export interface stakeData {
  ufoTotalPool: string
  LpTotalPool: string
  ufoTvl: string
  LpTvl: string
  ufoCount: string
  LpCount: string
  plasmaCount: string
}
