import './style.css'
import { FC } from 'react'

interface iTextEllipsis {
  text: string | null | undefined
  endChars?: number
  sx?: object
}

const TextEllipsis: FC<iTextEllipsis> = (props) => {
  const { text, endChars = 3, sx } = props
  const FONT_SCALE_FACTOR = 0.46
  const STARTS_MIN_CHARS = 3
  const endWidth = `1em * ${FONT_SCALE_FACTOR} * ${endChars}`
  const startWidth = `1em * ${FONT_SCALE_FACTOR} * ${STARTS_MIN_CHARS + 3}`

  return (
    <div className="textEllipsed">
      <span
        className="textEllipsed__text textEllipsed__text-main"
        style={{
          ...sx,
          maxWidth: `calc(100% - ${endWidth})`,
          minWidth: `calc(${startWidth})`,
        }}
      >
        {text?.substring(0, text?.length - 1 - endChars)}
      </span>
      <span
        className="textEllipsed__text textEllipsed__text-overlay"
        style={{ ...sx, maxWidth: `calc(100% - ${startWidth})` }}
      >
        {text?.substring(text?.length - 1 - endChars)}
      </span>
    </div>
  )
}

export default TextEllipsis
