import { Global } from '@emotion/react'
const Fonts = () => (
  <Global
    styles={`
      @font-face {
      font-family: 'SF UI Display';
      font-style: normal;
      font-weight: 100;
      src: url('./fonts/sf-ui-display-ultralight-58646b19bf205.woff') format('woff');
      }
      
  
      @font-face {
      font-family: 'SF UI Display';
      font-style: normal;
      font-weight: 200;
      src: url('./fonts/sf-ui-display-thin-58646e9b26e8b.woff') format('woff');
      }
      
  
      @font-face {
      font-family: 'SF UI Display';
      font-style: normal;
      font-weight: 300;
      src: url('./fonts/sf-ui-display-light-58646b33e0551.woff') format('woff');
      }
      
  
      @font-face {
      font-family: 'SF UI Display';
      font-style: normal;
      font-weight: 400;
      src: url('./fonts/sf-ui-display-medium-58646be638f96.woff') format('woff');
      }
      
  
      @font-face {
      font-family: 'SF UI Display';
      font-style: normal;
      font-weight: 500;
      src: url('./fonts/sf-ui-display-semibold-58646eddcae92.woff') format('woff');
      }
      
  
      @font-face {
      font-family: 'SF UI Display';
      font-style: normal;
      font-weight: 600;
      src: url('./fonts/sf-ui-display-bold-58646a511e3d9.woff') format('woff');
      }
      
  
      @font-face {
      font-family: 'SF UI Display';
      font-style: normal;
      font-weight: 700;
      src: url('./fonts/sf-ui-display-heavy-586470160b9e5.woff') format('woff');
      }
      
  
      @font-face {
      font-family: 'SF UI Display';
      font-style: normal;
      font-weight: 800;
      src: url('./fonts/sf-ui-display-black-58646a6b80d5a.woff') format('woff');
      }
      `}
  />
)
export default Fonts
