import { forwardRef } from 'react'
import LoadingBar from 'react-top-loading-bar'

interface ILoader {
  progress?: number
  finished?: number
  ref?: any
  onLoaderFinished?: () => void
}

const Loader = forwardRef(
  ({ progress = 0, onLoaderFinished }: ILoader, ref: any) => {
    return (
      <LoadingBar
        color="#f11946"
        progress={progress}
        className="loader-item"
        containerClassName="loader-container"
        shadow={false}
        onLoaderFinished={onLoaderFinished}
        ref={ref}
      />
    )
  }
)

export default Loader
