import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { BigNumber } from '@ethersproject/bignumber'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import toast from 'react-hot-toast'

import { OutlineButton } from '@/components'
import { showNotification } from '@/components/atoms/ToastNotifications'
import { useGlobalState } from '@/contexts/GlobalContext'
import { LockedDeposit } from '@/hooks/useLockedDeposits'
import { Staking__factory } from '@/types/typechain'
import {
  STAKE_RANGES,
  UFOETHLP_STAKING_CONTRACT_ADDRESS,
  UFO_STAKING_CONTRACT_ADDRESS,
} from '@/utils/constants'
import { formatToken } from '@/web3/helpers'
import { useContract, useWriteContract } from '@/web3/hooks'

export enum LockPool {
  UFO = 'UFO',
  UFO_ETHLP = 'UFO-ETH LP',
}
export interface MergedLockedDeposit extends LockedDeposit {
  pool: LockPool
}

export const StakesTable: FC<{ stakes: MergedLockedDeposit[] }> = ({
  stakes,
}) => {
  const [waiting, setWaiting] = useState<number>()
  const { contract: ufoStakingContract } = useContract(
    UFO_STAKING_CONTRACT_ADDRESS,
    Staking__factory
  )
  const { contract: ufoEthLpStakingContract } = useContract(
    UFOETHLP_STAKING_CONTRACT_ADDRESS,
    Staking__factory
  )

  const { ufoLockedDeposits, ufoEthLpLockedDeposits } = useGlobalState()

  const handleConfirmation = async () => {
    showNotification.success('Tokens Withdrawn')
    ufoLockedDeposits[1].refresh()
    ufoEthLpLockedDeposits[1].refresh()
    setWaiting(undefined)
  }

  const handleWait = async () => {
    showNotification.success('Waiting for transaction to finish')
  }

  const handleError = (error: any) => {
    showNotification.error(error?.data?.message || error.message)
  }

  const [withdrawUfo] = useWriteContract(ufoStakingContract, 'withdrawAmount', {
    onError: handleError,
    onResponse: handleWait,
    onConfirmation: handleConfirmation,
  })

  const [withdrawUfoEthLp] = useWriteContract(
    ufoEthLpStakingContract,
    'withdrawAmount',
    {
      onError: handleError,
      onResponse: handleWait,
      onConfirmation: handleConfirmation,
    }
  )

  const handleWithdraw = async (idx: number) => {
    const stake = stakes[idx]
    try {
      setWaiting(idx)
      if (stake.pool === LockPool.UFO) {
        await withdrawUfo(BigNumber.from(idx))
      } else {
        await withdrawUfoEthLp(BigNumber.from(idx))
      }
    } catch (error) {
      console.log(error)
      showNotification.error('Something went wrong')
      setWaiting(undefined)
    }
  }

  return (
    <Table variant="brand">
      <Thead>
        <Tr>
          <Th>AMOUNT</Th>
          <Th>POOL</Th>
          <Th>DURATION(Bonus)</Th>
          <Th>STAKED</Th>
          <Th>UNLOCKS</Th>
        </Tr>
      </Thead>
      <Tbody>
        {stakes.map((stake, idx) => {
          const lock = STAKE_RANGES.find(
            (range) =>
              range.weight === (stake.weight.toNumber() / 100).toString()
          )

          const isUnlocked =
            lock?.lock === 0 ||
            dayjs().endOf('day').isSame(dayjs.unix(stake.endDay).endOf('day'))

          const isWithdrawn = stake.withdrawn

          return (
            <Tr key={idx}>
              <Td>{formatToken(stake.amount)}</Td>
              <Td>{stake.pool}</Td>
              <Td>{`${lock?.label} (${lock?.weight}x)`}</Td>
              <Td>{dayjs.unix(stake.startDay).format('DD-MMM-YYYY')}</Td>
              {isWithdrawn ? (
                <Td>Withdrawn</Td>
              ) : isUnlocked ? (
                <Td>
                  <OutlineButton
                    small
                    text="Withdraw"
                    onClick={() => handleWithdraw(idx)}
                    isLoading={waiting === idx}
                    disabled={waiting === idx}
                    loadingText="Withdrawing"
                  />
                </Td>
              ) : (
                <Td>{dayjs.unix(stake.endDay).format('DD-MMM-YYYY')}</Td>
              )}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}
