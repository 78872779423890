import { Box, Flex, Heading, Link, Text, useMediaQuery } from '@chakra-ui/react'
import { useMemo, useEffect, useState } from 'react'

import { polygonChainsIDs } from '../../components/pages/Markets'

import { ReactComponent as UniswapLogo } from '@/assets/UniswapLogo.svg'
import { useWallet } from '@/web3/WalletContext'

const NeedSomeUfpPage = ({
  setNetworkModal,
  setNetworkModalType,
}: {
  setNetworkModal?: any
  setNetworkModalType?: any
}) => {
  const [isLargerThan670] = useMediaQuery('(max-width: 670px)')
  const { hexChainId: networkId } = useWallet()

  return (
    <Box>
      <Flex
        className="need-some-ufo"
        alignItems="center"
        borderRadius="27px"
        padding="30px"
        justifyContent="space-between"
        flexWrap="wrap"
        color="main.cream"
        marginTop="32px"
      >
        <Box>
          <Heading fontSize={!isLargerThan670 ? '32px' : '24px'}>
            Need Some UFO?
          </Heading>
          <Text>Buy UFO tokens in a few clicks.</Text>
        </Box>
        <Link
          display="flex"
          href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x249e38ea4102d0cf8264d3701f1a0e39c4f2dc3b&chain=mainnet"
          isExternal
          alignItems="center"
          border="2px solid #D2FF59"
          borderRadius="27px"
          padding="5px 15px"
          width={!isLargerThan670 ? '' : '100%'}
          justifyContent="space-between"
          marginTop={!isLargerThan670 ? '' : '27px'}
          textDecoration="none"
          className="link_without_borders"
          onClick={(e) => {
            setNetworkModalType(null)
            if (Object.values(polygonChainsIDs).includes(networkId)) {
              e.preventDefault()
              setNetworkModalType('eth')
              setNetworkModal(true)
            }
          }}
        >
          <Text marginRight="20px">Buy From Uniswap</Text>
          <Box>
            <UniswapLogo />
          </Box>
        </Link>
      </Flex>
    </Box>
  )
}

export default NeedSomeUfpPage
