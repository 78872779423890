import './style.css'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { FC } from 'react'

interface iCustomSliderProps {
  min: number
  max: number
  value: number
  setValue: (value: number) => void
}

export const CustomSlider: FC<iCustomSliderProps> = (props) => {
  const { min, max, value, setValue } = props
  const BACKGROUND_COLOR = '#102017'
  const FILL_COLOR = '#9bffe2'

  const getBackgroundGradient = () => {
    const percent = Number(max) / 100
    const percentage = percent * Number(value)

    return `linear-gradient(90deg, ${FILL_COLOR} ${min}%, ${FILL_COLOR} ${percentage}%, ${BACKGROUND_COLOR} ${percentage}%, ${BACKGROUND_COLOR} 100%)`
  }

  return (
    <Box mt="20px" mb="20px" width="100%">
      <Flex sx={{ position: 'relative' }}>
        <Box className="customSliderBg" background={getBackgroundGradient()} />
        <input
          type="range"
          className="customInput"
          min={min}
          max={max}
          step={1}
          value={value}
          onChange={(e) => {
            setValue(Number(e.target.value))
          }}
        />
      </Flex>
    </Box>
  )
}
