import { providers } from 'ethers'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useAccount, useDisconnect } from 'wagmi'

import { useEthersProvider } from '@/web3/hooks/useEthersProvider'
import { useEthersSigner } from '@/web3/hooks/useEthersSigner'

export type WalletContextType = {
  provider:
    | providers.JsonRpcProvider
    | providers.FallbackProvider
    | null
    | undefined
  signer: providers.JsonRpcSigner | null | undefined
  chainId: number | null | undefined
  address: string | null | undefined
  hexChainId: string
  disconnect: () => void
  isConnected: boolean
  isConnecting: boolean
}

export const WalletContext = createContext<WalletContextType>({
  provider: null,
  signer: null,
  chainId: null,
  address: null,
  hexChainId: '',
  disconnect: () => undefined,
  isConnected: false,
  isConnecting: false,
})

export const WalletProvider = ({ children }) => {
  const { address, chainId, isConnected, isConnecting } = useAccount()
  const { disconnect } = useDisconnect()

  const provider = useEthersProvider({ chainId })
  const signer = useEthersSigner({ chainId })
  const getHexChainId = (chainId) => {
    if (chainId == null || chainId == undefined) return ''
    else return `0x${Number(chainId).toString(16)}`
  }
  return (
    <WalletContext.Provider
      value={{
        provider,
        signer,
        address,
        hexChainId: getHexChainId(chainId),
        chainId,
        isConnected,
        isConnecting,
        disconnect,
      }}
    >
      {children}
    </WalletContext.Provider>
  )
}

export const useWallet = (): WalletContextType => useContext(WalletContext)

export function useProvider() {
  const context = useContext(WalletContext)
  return context.provider
}
