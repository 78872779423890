import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'

const colors = {
  main: {
    lavender: '#ADABFF',
    purple: '#8924F4',
    neonGreen: 'rgba(224, 255, 35, 0.9)',
    orange: '#FA937D',
    neonBlue: '#06F0F9',
    black: '#12111E',
    white: '#fff',
    dark: '#000',
    cream: '#F8F4E4',
    purpleHighlight: '#A65AF6',
    deepPurple: '#36006E',
    spaceGrey: '#102017',
    // neonGreen: "#59FFCF",
    acidGreen: '#D2FF59',
    whiteBlue: '#9BFFE2',
  },

  gradient: {
    darkBlue: 'linear-gradient(327.36deg, #121114 -16.72%, #100F50 116.82%);',
    darkBlue2: 'linear-gradient(273.4deg, #191227 1.2%, #151466 98.8%)',
    gray: 'linear-gradient(180deg, rgba(18, 17, 30, 0) 0%, #121121 100%)',
    lightBlue: 'linear-gradient(136.36deg, #4C00A2 -10.41%, #05EFF8 102.44%)',
    lightGlow2:
      'linear-gradient(270deg, rgba(5, 3, 105, 0) 0%, #FFFFFF 49.8%, rgba(5, 3, 105, 0) 100%);',
    lightGlow:
      'linear-gradient(270deg, rgba(5, 3, 105, 0) 0%, #FFFFFF 49.8%, rgba(5, 3, 105, 0) 100%)',
    bg: 'radial-gradient(94.5% 94.5% at 14.55% 5.5%, rgba(33, 148, 236, 0.234553) 0%, rgba(0, 0, 0, 0.0001) 100%), radial-gradient(76.12% 76.12% at 77.9% 41.44%, #301440 0%, rgba(0, 0, 0, 0.0001) 100%), radial-gradient(26.87% 26.87% at 60.64% 51.87%, rgba(11, 221, 242, 0.514189) 0%, rgba(11, 11, 11, 0.0001) 100%), #000000;',
    inputBg: 'linear-gradient(136.36deg, #4C00A2 -10.41%, #05EFF8 102.44%)',
    purpleToOrange:
      'background-image: linear-gradient(180deg, #FA866E 0%, #8924F4 100%)',
  },
}

const shadows = {
  blip: '0px 0px 14px #06F0F9',
}

const Button = {
  sizes: {
    md: {
      py: '7',
      px: '12',
      fontSize: '18px',
    },
    sm: {
      py: '3',
      px: '7',
      fontSize: '18px',
    },
  },
  variants: {
    // needed for outline button component
    unstyled: {},
    solid: {
      bg: 'gradient.lightBlue',
      borderRadius: 'md',
      fontWeight: 400,
      letterSpacing: '0.5px',
    },
  },
}

const Heading = {
  variants: {
    h3: {
      fontSize: '48px',
      fontWeight: 500,
      lineHeight: '54px',
    },
    h4: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: '30px',
    },
    h6: {
      fontSize: '22px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
  },
}

const Text = {
  variants: {
    overline: {
      fontSize: '13px',
      textTransform: 'uppercase',
    },
    body1: {
      fontSize: '18px',
      fontWeight: '300',
    },
    body2: {
      fontSize: '18px',
      fontWeight: '600',
    },
    subtitle1: {
      fontSize: '18px',
      fontWeight: '300',
      letterSpacing: '1px',
      lineHeight: '24px',
    },
    subtitle2: {
      fontSize: '16px',
      lineHeight: '22px',
    },
    caption: {
      fontSize: '13px',
      fontWeight: '400',
      letterSpacing: '0.5px',
      lineHeight: '24px',
    },
  },
}

const Table = {
  variants: {
    brand: {
      thead: {
        position: 'relative',
        top: '5',
      },
      table: {
        bordercollapse: 'separate',
        borderSpacing: '0 12px',
      },
      th: {
        color: 'main.lavender',
        fontWeight: 400,
        paddingBottom: 0,
        paddingTop: 0,
      },
      tbody: {
        tr: {
          bg: 'gradient.gray',
        },
        td: {
          color: 'main.white',
          _first: {
            borderTopStartRadius: 'md',
            borderBottomStartRadius: 'md',
          },
          _last: {
            borderBottomEndRadius: 'md',
            borderTopEndRadius: 'md',
          },
        },
      },
    },
  },
}

const Input = {
  variants: {
    outline: {
      field: {
        bg: '#121114',
        color: 'white',
        borderColor: '#121114',
      },
    },
  },
}

export const theme = extendTheme(
  {
    components: {
      Button,
      Heading,
      Table,
      Text,
      Input,
    },
    colors,
    shadows,
    // fonts: {
    //   heading: 'SF UI Display',
    //   body: 'SF UI Display',
    // },
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  withDefaultColorScheme({
    colorScheme: 'primary',
  })
)
