import { Route, Switch } from 'react-router-dom'

import { ComingSoon, Markets } from '@/components'
import ScrollToTop from '@/utils/scrollToTop'

export const ROUTES = [
  {
    path: '',
    component: Markets,
    title: '',
  },
  // {
  //   path: '/mint',
  //   component: ComingSoon,
  //   menu: false,
  //   exact: true,
  //   title: 'Mint',
  // },
  // {
  //   path: '/collections',
  //   component: ComingSoon,
  //   menu: false,
  //   exact: true,
  //   title: 'Collections',
  // },
  // {
  //   path: '/help',
  //   component: ComingSoon,
  //   menu: false,
  //   exact: true,
  //   title: 'Help',
  // },
  {
    path: 'stake',
    component: ComingSoon,
    menu: true,
    exact: true,
    title: 'Stake',
  },
  {
    path: 'leaderboard',
    component: ComingSoon,
    menu: true,
    exact: true,
    title: 'Leaderboard',
  },
  {
    path: 'rewards',
    component: ComingSoon,
    menu: true,
    exact: true,
    title: 'Rewards',
  },
  {
    path: 'faq',
    component: ComingSoon,
    menu: false,
    exact: true,
    title: 'FAQ',
  },
  {
    path: 'how_to_stake',
    component: ComingSoon,
    menu: false,
    exact: true,
    title: 'How to stake',
  },
]

export default function Routes() {
  return (
    <>
      {/* <ScrollToTop /> */}
      <Switch>
        {ROUTES.map((props, index) => (
          <Route {...props} key={index} />
        ))}
      </Switch>
    </>
  )
}
