import { Text, Box, Center, Link } from '@chakra-ui/react'
import { switchChain } from '@wagmi/core'
import React, { FC, useMemo } from 'react'
import { mainnet, polygon } from 'viem/chains'

import {
  ethChainsIDs,
  polygonChainsIDs,
  mainNetSupportIDsNetworksWithName,
} from '../../pages'

import { ModalPopup } from '@/components'
import ButtonBase from '@/components/button'
import { config } from '@/index'
import { useWallet } from '@/web3/WalletContext'

interface NetworkModalProps {
  isOpen: boolean
  onCloseModal: () => void
  networkModalType?: any
}

export const changeNetwork = async ({ networkName }) => {
  try {
    if (networkName === 'eth') {
      await switchChain(config, { chainId: mainnet.id })
    } else {
      await switchChain(config, { chainId: polygon.id })
    }
  } catch (err) {}
}

export const NetworkModal: FC<NetworkModalProps> = ({
  isOpen,
  onCloseModal,
  networkModalType,
}) => {
  const { hexChainId: networkId } = useWallet()

  // const networkId = useMemo(
  //   () => window?.ethereum?.chainId,

  //   [window?.ethereum?.chainId]
  // )

  const currentNetworkName = mainNetSupportIDsNetworksWithName.find(
    (network) => network[1] == networkId
  )

  const handleNetworkSwitch = async (networkName) => {
    onCloseModal()
    await changeNetwork({ networkName })
  }

  return (
    <ModalPopup
      title="Change Network"
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      key={networkModalType}
    >
      <Box display="flex" mb={8}>
        <Text
          color="main.white"
          variant="caption"
          sx={{
            fontSize: '20px',
          }}
        >
          {networkModalType === 'eth' ? (
            <>
              These tokens can be purchased only on the Ethereum network. Please
              switch your network.
            </>
          ) : (
            <>
              You are currently connected to{' '}
              {currentNetworkName ? currentNetworkName[0] : 'non-supported'}{' '}
              network, whereas our staking dApp runs on Polygon. Please bridge
              your tokens to Polygon and then switch networks to Polygon.
            </>
          )}
        </Text>
      </Box>
      <Box>
        {networkModalType !== 'eth' && (
          <Link
            href="https://wallet.polygon.technology/polygon/bridge/deposit"
            isExternal
            sx={{ textDecoration: 'none !important' }}
          >
            <ButtonBase
              minW="110px"
              w="100%"
              h="49px"
              position="relative"
              background="linear-gradient(180deg, #FA937D 0%, #8924F4 100%)"
              color="main.orange"
              justifyContent="center"
              p="2px"
              overflow="hidden"
              outline="none"
              fontStyle="normal"
              _hover="none"
              mb="20px"
              onClick={() => {
                onCloseModal()
              }}
              disabled={false}
            >
              <Center
                w="100%"
                h="100%"
                background="#102017"
                borderRadius="50px"
                fontStyle="normal"
                fontWeight="700"
                fontSize="14px"
                lineHeight="20px"
              >
                Bridge Tokens to Polygon
              </Center>
            </ButtonBase>
          </Link>
        )}

        <ButtonBase
          minW="110px"
          w="100%"
          h="49px"
          position="relative"
          background="linear-gradient(180deg, #FA937D 0%, #8924F4 100%);"
          color="#fff"
          justifyContent="center"
          p="2px"
          overflow="hidden"
          outline="none"
          fontStyle="normal"
          fontWeight="700"
          _hover="none"
          mb="20px"
          onClick={() =>
            networkModalType === 'eth'
              ? handleNetworkSwitch('eth')
              : handleNetworkSwitch('polygon')
          }
        >
          {networkModalType === 'eth' ? (
            <>Switch Network To Ethereum</>
          ) : (
            <>Switch Network to Polygon</>
          )}
        </ButtonBase>
      </Box>
    </ModalPopup>
  )
}
