import { FC } from 'react'
import toast from 'react-hot-toast'

import { ReactComponent as AtentionIcon } from '@/assets/img/toasterIcons/attention.svg'
import { ReactComponent as ErrorIcon } from '@/assets/img/toasterIcons/error.svg'
import { ReactComponent as SuccessIcon } from '@/assets/img/toasterIcons/success.svg'

interface iToastNotificationProps {
  message: string
}

const mainNotificationStyles = {
  display: 'flex',
  width: '100vw',
  height: '72px',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '18px',
  lineHeight: '19px',
  color: '#F8F4E4',
  margin: '0 -16px',
  textTransfom: 'capitalize',
}

const ToastErrorNotification: FC<iToastNotificationProps> = ({ message }) => {
  return (
    <div style={{ ...mainNotificationStyles, background: '#6E0000' }}>
      <ErrorIcon width="45px" height="45px" />
      <span style={{ marginLeft: '20px' }}>{message}</span>
    </div>
  )
}

const ToastSuccessNotification: FC<iToastNotificationProps> = ({ message }) => {
  return (
    <div style={{ ...mainNotificationStyles, background: '#36006E' }}>
      <SuccessIcon width="45px" height="45px" />
      <span style={{ marginLeft: '20px' }}>{message}</span>
    </div>
  )
}

const ToastWarningNotification: FC<iToastNotificationProps> = ({ message }) => {
  return (
    <div
      style={{
        ...mainNotificationStyles,
        background: '#D2FF59',
        color: '#102017',
      }}
    >
      <AtentionIcon width="44px" height="40px" />
      <span style={{ marginLeft: '20px' }}>{message}</span>
    </div>
  )
}

export const showNotification = {
  error(message: string) {
    toast.custom(<ToastErrorNotification message={message} />)
  },
  success(message: string) {
    toast.custom(<ToastSuccessNotification message={message} />)
  },
  warning(message: string) {
    toast.custom(<ToastWarningNotification message={message} />)
  },
}
