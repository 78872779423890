import { Flex, Heading, Text, Center, Box, Link } from '@chakra-ui/react'
import { FC, useState, useEffect, useRef, useMemo } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import NumberFormat from 'react-number-format'

import {
  ethChainsIDs,
  polygonChainsIDs,
  mainNetSupportIDsNetworks,
} from '../pages'
import { changeNetwork } from '../pages/Markets/NetworkModal'

import { ReactComponent as Copy } from '@/assets/img/Copy.svg'
import { ReactComponent as Disconnect } from '@/assets/img/Disconnect.svg'
import { ReactComponent as QuestionSign } from '@/assets/img/QuestionSign.svg'
import { ReactComponent as Close } from '@/assets/img/closeIcon.svg'
import { ReactComponent as EthIcon } from '@/assets/img/eth-icon.svg'
import { ReactComponent as PlasmaBg } from '@/assets/img/plasma-bg.svg'
import { ReactComponent as Plasma } from '@/assets/img/plasma.svg'
import { ReactComponent as PolygonIcon } from '@/assets/img/polygon-icon.svg'
import { ReactComponent as Polygon } from '@/assets/img/polygon.svg'
import { ReactComponent as UfoBg } from '@/assets/img/ufo-bg.svg'
import { ReactComponent as UfoEthBg } from '@/assets/img/ufo-eth-bg.svg'
import { ReactComponent as UfoEthMainIcon } from '@/assets/img/ufo-eth-icon.svg'
import { ReactComponent as UfoMainIcon } from '@/assets/img/ufo-icon.svg'
import Loader from '@/components/Loader'
import ButtonBase from '@/components/button'
import TextEllipsis from '@/components/molecules/TextEllipsis/index'
import {
  getUFOBalance,
  getLPBalance,
  getPlasmaBalance,
} from '@/components/pages/Markets/GetWalletBallance'
import { useWallet } from '@/web3/WalletContext'

interface iMyWallet {
  address: string | null | undefined
  disconnect: () => void
  openVideoModal?: () => void
}

const myWalletStyles = {
  menuBox: {
    flexDirection: 'column',
    color: '#F8F4E4',
    backgroundColor: '#102017',
    borderRadius: '27px',
  },
  heading: {
    fontSize: '18px',
    fontWeight: '800',
    lineHeight: '23px',
  },
  address: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '19px',
  },
  dataWrap: {
    flexDirection: 'column',
    borderTop: '1px solid rgba(255,255,255, .2)',
    borderBottom: '1px solid rgba(255,255,255, .2)',
    padding: '15px 20px',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '21px',
  },
  dataRow: {
    justifyContent: 'space-between',
  },
  logo: {
    width: '48px',
    height: '32px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: '10px',
    borderRadius: '50%',
  },
  row: {
    alignItems: 'center',
  },
}

const MyWallet: FC<iMyWallet> = (props) => {
  const { disconnect, openVideoModal } = props
  const [ufoBalance, setUfoBalance] = useState<string>('')
  const [lpBalance, setLpBalance] = useState<string>('')
  const [plasmaBalance, setPlasmaBalance] = useState<string>('')
  const { signer, chainId, address, hexChainId: networkId } = useWallet()
  const endChars = address ? address.length / 2 : 0
  const lpBalanceLoader = useRef<any | null>(null)
  const ufoBalanceLoader = useRef<any | null>(null)
  const plasmaBalanceLoader = useRef<any | null>(null)
  const ethChainsArray = Object.values(ethChainsIDs)
  const polygonChainsArray = Object.values(polygonChainsIDs)

  const currentNetwork = mainNetSupportIDsNetworks.includes(networkId)

  const handleNetworkSwitch = async (networkName) => {
    await changeNetwork({ networkName })
  }

  const fetchLpBalance = () => {
    new Promise((res, rej) => {
      lpBalanceLoader.current && lpBalanceLoader?.current?.continuousStart()
      const lpBalance = getLPBalance(signer, chainId, address)
      if (lpBalance) {
        res(lpBalance)
      }
    }).then((data: any) => {
      setLpBalance(data)
    })
  }

  const fetchUfoBalance = async () => {
    new Promise((res, rej) => {
      ufoBalanceLoader.current && ufoBalanceLoader?.current?.continuousStart()
      const ufoBalance = getUFOBalance(signer, chainId, address)

      if (ufoBalance) {
        res(ufoBalance)
      }
    }).then((data: any) => {
      setUfoBalance(data)
    })
  }

  const fetchPlasmaBalance = async () => {
    new Promise((res, rej) => {
      plasmaBalanceLoader.current &&
        plasmaBalanceLoader?.current?.continuousStart()
      const plasmaBalance = getPlasmaBalance(signer, chainId, address)

      if (plasmaBalance) {
        res(plasmaBalance)
      }
    }).then((data: any) => {
      setPlasmaBalance(data)
    })
  }

  useEffect(() => {
    fetchLpBalance()
    fetchUfoBalance()
    fetchPlasmaBalance()
  }, [lpBalance, ufoBalance, plasmaBalance, signer, chainId, address])

  return (
    <Flex sx={myWalletStyles.menuBox}>
      {currentNetwork ? (
        <>
          <Box sx={{ padding: '15px 20px 15px 20px' }}>
            <Box mb="2">
              {ethChainsArray.includes(networkId) ? (
                <Flex alignItems="center">
                  <EthIcon width="8" height="12" fill="#6773B1" />
                  <Text ml="2">Ethereum network</Text>
                </Flex>
              ) : polygonChainsArray.includes(networkId) ? (
                <Flex alignItems="center">
                  <PolygonIcon width="14" height="12" fill="#8924F4" />
                  <Text ml="2">Polygon network</Text>
                </Flex>
              ) : null}
            </Box>
            <Heading sx={myWalletStyles.heading}>My Wallet</Heading>
            <Box sx={{ position: 'relative', paddingRight: '20px' }}>
              <TextEllipsis
                endChars={endChars}
                text={address}
                sx={myWalletStyles.address}
              />
              <CopyToClipboard text={address}>
                <Box
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: '5px',
                    top: '5px',
                  }}
                >
                  <Copy />
                </Box>
              </CopyToClipboard>
            </Box>
          </Box>
          <Flex sx={myWalletStyles.dataWrap}>
            <Flex mb="10px" color="#D2FF59" sx={myWalletStyles.row}>
              {ufoBalance ? (
                <>
                  <Flex sx={myWalletStyles.logo}>
                    <UfoBg width="32" height="32" />
                  </Flex>
                  <Text>UFO</Text>
                  <Text ml="auto">
                    <NumberFormat
                      value={Number(ufoBalance).toFixed(0)}
                      displayType="text"
                      thousandSeparator
                      renderText={(value) => <>{value}</>}
                    />
                  </Text>
                </>
              ) : (
                <Loader ref={ufoBalanceLoader} />
              )}
            </Flex>
            <Flex mb="10px" color="#BEE949" sx={myWalletStyles.row}>
              {lpBalance ? (
                <>
                  <Flex sx={myWalletStyles.logo}>
                    <UfoEthBg width="48" height="32" />
                  </Flex>
                  <Text>UFO-ETH LP</Text>
                  <Text ml="auto">
                    <NumberFormat
                      value={Number(lpBalance).toFixed(0)}
                      displayType="text"
                      thousandSeparator
                      renderText={(value) => <>{value}</>}
                    />
                  </Text>
                </>
              ) : (
                <Loader ref={lpBalanceLoader} />
              )}
            </Flex>
            <Flex color="#8924F4" sx={myWalletStyles.row}>
              {!ethChainsArray.includes(networkId) && (
                <>
                  {plasmaBalance ? (
                    <>
                      <Flex sx={myWalletStyles.logo}>
                        <PlasmaBg width="32" height="32" />
                      </Flex>
                      <Text>Plasma</Text>
                      <Text ml="auto">
                        <NumberFormat
                          value={Number(plasmaBalance).toFixed(0)}
                          displayType="text"
                          thousandSeparator
                          renderText={(value) => <>{value}</>}
                        />
                      </Text>
                    </>
                  ) : (
                    <Loader ref={plasmaBalanceLoader} />
                  )}
                </>
              )}
            </Flex>
            <Box sx={{ marginTop: '15px' }}>
              <Link
                href="https://wallet.polygon.technology/polygon/bridge/deposit"
                isExternal
                sx={{ textDecoration: 'none !important' }}
              >
                <ButtonBase
                  minW="110px"
                  w="100%"
                  h="48px"
                  position="relative"
                  background="#FA937D"
                  color="main.orange"
                  justifyContent="center"
                  p="2px"
                  overflow="hidden"
                  outline="none"
                  fontStyle="normal"
                  _hover="none"
                  mb="10px"
                  disabled={!plasmaBalance}
                >
                  <Center
                    w="100%"
                    h="100%"
                    background="#102017"
                    borderRadius="50px"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="14px"
                    lineHeight="20px"
                    padding="0 2px 0 15px"
                  >
                    {plasmaBalance ? 'Bridge Tokens to Polygon' : 'Loading...'}
                    <Box sx={{ marginLeft: 'auto' }}>
                      <Polygon width="40" height="40" />
                    </Box>
                  </Center>
                </ButtonBase>
              </Link>
              {ethChainsArray.includes(networkId) && (
                <ButtonBase
                  minW="110px"
                  w="100%"
                  h="48px"
                  position="relative"
                  background="#FA937D"
                  color="main.orange"
                  justifyContent="center"
                  p="2px"
                  overflow="hidden"
                  outline="none"
                  fontStyle="normal"
                  _hover="none"
                  onClick={() => handleNetworkSwitch('polygon')}
                >
                  <Center
                    w="100%"
                    h="100%"
                    background="#102017"
                    borderRadius="50px"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="14px"
                    lineHeight="20px"
                    padding="0 2px 0 15px"
                  >
                    Switch Network to Polygon
                    <Box sx={{ marginLeft: 'auto' }}>
                      <Polygon width="40" height="40" />
                    </Box>
                  </Center>
                </ButtonBase>
              )}
              <Flex
                justifyContent="center"
                sx={{
                  padding: '10px 20px',
                }}
              >
                <Box
                  color="#D2FF59"
                  sx={{
                    cursor: 'pointer',
                  }}
                  display="flex"
                  alignItems="center"
                  fontWeight="400"
                  onClick={openVideoModal}
                >
                  <Box mr="10px">
                    <QuestionSign />
                  </Box>
                  How To Stake?
                </Box>
              </Flex>
            </Box>
          </Flex>
        </>
      ) : (
        <Box
          sx={{
            padding: '20px',
          }}
        >
          <Heading
            sx={{
              color: '#fff',
              fontSize: '14px',
              lineHeight: '1.4',
              textAlign: 'center',
            }}
            as="p"
            size="lg"
            fontWeight="700"
          >
            You are currently connected to{' '}
            {currentNetwork ? currentNetwork[1] : 'non-supported'} network,
            whereas our staking dApp runs on Polygon. Please bridge your tokens
            to Polygon and then switch networks to Polygon.
          </Heading>
        </Box>
      )}

      <Flex
        justifyContent="center"
        sx={{
          padding: '15px 20px',
        }}
      >
        <Box
          color="#D2FF59"
          sx={{
            cursor: 'pointer',
          }}
          display="flex"
          alignItems="center"
          onClick={() => disconnect()}
        >
          <Box mr="10px">
            <Disconnect />
          </Box>
          Disconnect Wallet
        </Box>
      </Flex>
    </Flex>
  )
}

export default MyWallet
