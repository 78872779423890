import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { LanguageInitialState } from './language/types'

export interface RootState {
  language: LanguageInitialState
}

export const CLEAR_ALL_REDUCERS_DATA = 'CLEAR_ALL_REDUCERS_DATA'

export type RootThunkAction = ThunkAction<void, RootState, null, AnyAction>
