import { Button, Flex, FlexProps, Text } from '@chakra-ui/react'
import { FC } from 'react'

export const OutlineButton: FC<
  FlexProps & {
    text: string
    small?: boolean
    isLoading?: boolean
    disabled?: boolean
    loadingText?: string
  }
> = ({ text, small, isLoading, disabled, loadingText, ...props }) => {
  return (
    <Button
      variant="unstyled"
      bg="linear-gradient(136.36deg, #4C00A2 -10.41%, #05EFF8 102.44%)"
      h={small ? '32px' : '56px'}
      p="1.5px"
      disabled={disabled}
    >
      <Flex
        w="full"
        h="full"
        py={small ? '2.5px' : '14.5px'}
        px={small ? '7' : '12'}
        borderRadius="md"
        justifyContent="center"
        alignItems="center"
        bg="rgba(18, 17, 20)"
        {...props}
      >
        <Text
          sx={{
            background:
              'linear-gradient(136.36deg, #4C00A2 -10.41%, #05EFF8 102.44%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            letterSpacing: '0.5px',
          }}
        >
          {isLoading ? loadingText : text}
        </Text>
      </Flex>
    </Button>
  )
}
