import {
  Box,
  Flex,
  Heading,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react'
import { FC, useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'

import TextEllipsis from '../molecules/TextEllipsis'

import { ReactComponent as FilesLogo } from '@/assets/files-logo.svg'
import { getFormatedDateStringForLeaderBoard } from '@/utils/getFormatedDateStringForLeaderBoard'
interface iStakesRow {
  rank: number
  type: string
  depositor: string
  myShare: string
  date: string
  totalStakedInDollars: string
}

const stakeRowStyles = {
  box: {
    padding: '10px 30px',
    borderRadius: '27px',
    alignItems: 'center',
    fontSize: '16',
    fontWeight: '700',
    fontFamily: 'sans-regular',
    letterSpacing: '0.505263px',
    maxHeight: '55px',
  },
  dateAmountWrap: {
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}

const stakeMobileRowStyle = {
  box: {
    flexDirection: 'column',
    borderRadius: '27px',
    padding: '18px 30px 12px 30px',
    fontSize: '16',
    fontWeight: '700',
    fontFamily: 'sans-regular',
    letterSpacing: '0.505263px',
  },
  addressWrap: {
    justifyContent: 'space-between',
    marginBottom: '8px',
    position: 'relative',
  },
  reducedText: {
    fontSize: '12',
    fontWeight: '400',
    lineHeight: '19px',
  },
}

const tooltipStyle = {
  display: 'flex',
  position: 'absolute' as const,
  top: '-3em',
  left: 'calc(100% - 3.7em)',
  fontSize: '10px',
  background: '#D2FF59',
  opacity: 0.8,
  borderRadius: '6px',
  padding: '0.3em',
  color: '#000',
  transition: 'all .2s ease',
}

const StakeRow: FC<iStakesRow> = (props) => {
  const { box, dateAmountWrap } = stakeRowStyles
  const { rank, depositor, myShare, date, totalStakedInDollars } = props
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  const handleCopyAddress = (text: string): void => {
    navigator.clipboard.writeText(text)
    setShowTooltip(true)
    setTimeout(() => setShowTooltip(false), 1000)
  }

  return (
    <Flex background="main.spaceGrey" color="main.cream" sx={box}>
      <Flex className="rank">{`#${rank}`}</Flex>
      <Flex ml="32px" mr="23px" position="relative">
        {showTooltip && <Text style={tooltipStyle}>Copied</Text>}
        <div style={{ maxWidth: '120px' }}>
          <TextEllipsis text={depositor} endChars={6} />
        </div>
        <FilesLogo
          onClick={() => handleCopyAddress(depositor)}
          cursor="pointer"
          style={{ marginLeft: '10px' }}
        />
      </Flex>
      <Flex sx={dateAmountWrap}>
        <Text>{getFormatedDateStringForLeaderBoard(Number(date))}</Text>
        <Flex flexDirection="column" textAlign="end">
          <Text color="#5DFFCF">
            <NumberFormat
              value={Number(myShare).toFixed(0)}
              displayType="text"
              thousandSeparator
              renderText={(value) => <>{value}</>}
            />
          </Text>
          <Text fontSize="12px" fontWeight="400">
            <NumberFormat
              value={Number(totalStakedInDollars).toFixed(0)}
              displayType="text"
              thousandSeparator
              renderText={(value) => <>{`$ ${value}`}</>}
            />
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

const StakeRowMobile: FC<iStakesRow> = (props) => {
  const { box, addressWrap, reducedText } = stakeMobileRowStyle
  const { depositor, myShare, type, date, totalStakedInDollars } = props
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const handleCopyAddress = (text: string): void => {
    navigator.clipboard.writeText(text)
    setShowTooltip(true)
    setTimeout(() => setShowTooltip(false), 1000)
  }
  return (
    <Flex background="main.spaceGrey" color="main.cream" sx={box}>
      <Flex sx={addressWrap}>
        {showTooltip && <Text style={tooltipStyle}>Copied</Text>}
        <div
          style={{ display: 'flex', flexDirection: 'column', maxWidth: '95%' }}
        >
          <TextEllipsis
            text={depositor}
            endChars={6}
            sx={{ fontSize: '12px' }}
          />
        </div>
        <FilesLogo
          onClick={() => handleCopyAddress(depositor)}
          cursor="pointer"
          style={{ marginLeft: '10px' }}
        />
      </Flex>
      <Flex justify="space-between">
        <Flex direction="column">
          <Text sx={reducedText}>Staked at</Text>
          <Text>{getFormatedDateStringForLeaderBoard(Number(date))}</Text>
        </Flex>
        <Flex direction="column" textAlign="right">
          <Text sx={reducedText}>
            Amount, {type === 'lp' ? 'UFO-ETH LP' : 'UFO'}
          </Text>
          <Text color="#5DFFCF">
            <NumberFormat
              value={Number(myShare).toFixed(0)}
              displayType="text"
              thousandSeparator
              renderText={(value) => <>{value}</>}
            />
          </Text>
          <Text sx={reducedText}>
            <NumberFormat
              value={Number(totalStakedInDollars).toFixed(0)}
              displayType="text"
              thousandSeparator
              renderText={(value) => <>{`$ ${value}`}</>}
            />
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

const StakesRow: FC<iStakesRow> = (props) => {
  const [isSmallerThan986] = useMediaQuery('(max-width:986px)')
  return (
    <>
      {isSmallerThan986 ? (
        <StakeRowMobile {...props} />
      ) : (
        <StakeRow {...props} />
      )}
    </>
  )
}

export default StakesRow
