import {
  Box,
  Heading,
  Stack,
  Text,
  VStack,
  Flex,
  Link,
  useMediaQuery,
} from '@chakra-ui/react'

// import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { BigNumber } from '@ethersproject/bignumber'
import { useState, useMemo } from 'react'
import toast from 'react-hot-toast'
import NumberFormat from 'react-number-format'

import { polygonChainsIDs } from '../Markets'

import { ReactComponent as UfoIcon } from '@/assets/img/UFO_icon.svg'
import { ReactComponent as ArrowOnPurple } from '@/assets/img/arrowOnPurple.svg'
import { BrandedBox, OutlineButton } from '@/components'
import { showNotification } from '@/components/atoms/ToastNotifications'
import ButtonBase from '@/components/button'
import { PoolShare } from '@/components/pages/Markets/PoolShare'
import { CustomToolTip } from '@/components/tooltip'
import { useGlobalState } from '@/contexts/GlobalContext'
import { Staking__factory } from '@/types/typechain'
import { useWallet } from '@/web3/WalletContext'
import { formatNumber, formatToken } from '@/web3/helpers'
import {
  useCGPrice,
  useContract,
  useReadContract,
  useWriteContract,
} from '@/web3/hooks'

export const PoolSummary = ({
  contract,
  name,
  ufoTotalPool,
  userShare,
  userShareInDollar,
  title,
  tvl,
  setStakeModal,
  setModalType,
  modalType,
  APR,
  btnTitle,
  link,
  setNetworkModal,
  setNetworkModalType,
}: {
  contract: string
  name: string
  ufoTotalPool: string
  userShare: string
  userShareInDollar: string
  title: string
  tvl: any
  APR: string
  link: string
  btnTitle: string
  modalType: number
  setStakeModal: (toggle: boolean) => void
  setModalType: (type: number) => void
  setNetworkModal?: any
  setNetworkModalType?: any
}) => {
  const { contract: stakingContract } = useContract(contract, Staking__factory)

  const [waiting, setWaiting] = useState(false)
  const handleConfirmation = async () => {
    showNotification.success('Plasma Claimed')
    setWaiting(false)
  }

  const [isLargerThan992] = useMediaQuery('(max-width: 992px)')
  const handleHarvested = async () => {
    showNotification.success('Waiting for transaction to finish')
  }

  const handleError = (error: any) => {
    showNotification.error(error?.data?.message || error.message)
  }

  const [harvestPlasma] = useWriteContract(stakingContract, 'withdrawReward', {
    onConfirmation: handleConfirmation,
    onError: handleError,
    onResponse: handleHarvested,
  })
  const { response: currentDay } = useReadContract(
    stakingContract,
    'getCurrentDay'
  )
  const { response: totalLpTokensLockedInThisContract } = useReadContract(
    stakingContract,
    'totalLpTokensLocked',
    { autoUpdate: true }
  )
  const { response: totalWeightedLocked } = useReadContract(
    stakingContract,
    'totalWeightedLockedForTheDay',
    { autoUpdate: true },
    currentDay ?? 0
  )

  const { ufoLockedDeposits, ufoEthLpLockedDeposits } = useGlobalState()

  const weightedLocked = (() => {
    if (name === 'UFO') {
      return ufoLockedDeposits[0].reduce(
        (acc, curr) => acc.add(curr.amount.mul(curr.weight).div(100)),
        BigNumber.from(0)
      )
    } else {
      return ufoEthLpLockedDeposits[0].reduce(
        (acc, curr) => acc.add(curr.amount.mul(curr.weight).div(100)),
        BigNumber.from(0)
      )
    }
  })()

  const { address, provider, hexChainId: networkId } = useWallet()

  // const networkId = useMemo(
  //   () => window?.ethereum?.chainId,

  //   [window?.ethereum?.chainId]
  // )

  // TODO: undecided what to use here.
  const { response: availablePlasma } = useReadContract(
    stakingContract,
    'possibleTotalPlasmaPoints',
    { autoUpdate: true }
  )

  const { response: lpTokensLocked } = useReadContract(
    stakingContract,
    'lpTokensLocked',
    { autoUpdate: true },
    address ?? ''
  )

  const { response: rewardAmount } = useReadContract(
    stakingContract,
    'getRewardAmount',
    { autoUpdate: true },
    address ?? ''
  )

  const claimPlasma = async () => {
    // lockAmount cannot be empty

    // lockAmount cannot be more than ufoBalance
    if (rewardAmount?.isZero()) {
      showNotification.error('Please wait for a day for plasma to accrue')
      return
    }

    try {
      setWaiting(true)
      await harvestPlasma()
    } catch (error) {
      showNotification.error('Something went wrong')
    } finally {
      setWaiting(false)
    }
  }

  const [ufoPrice] = useCGPrice({ tokenId: 'ufo-gaming' })

  const myShare = () => {
    const total = Number(formatToken(totalWeightedLocked))
    const locked = Number(formatToken(weightedLocked))
    const share = total === 0 ? 0 : (locked * 100) / total
    return formatNumber(share)
  }

  return (
    (!isLargerThan992 && (
      <BrandedBox flex="1" width="100%">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          bg="#102017"
          borderRadius="27px"
          w="100%"
          p={{ base: '30px', md: '8px' }}
          color="white"
          // flexDirection={{ base: 'column', md: 'row' }}
          sx={{
            paddingTop: '20px',
            borderTop: '1px solid #111',
            marginBottom: '20px',
          }}
        >
          <Flex
            w="100%"
            ml="30px"
            // flexDirection={{ base: 'column', sm: 'row' }}
            flexDirection={isLargerThan992 ? 'column' : 'row'}
            gap={{ base: '8px', sm: '0' }}
            // m="3px 0 0 30px"
            justifyContent="center"
            alignItems="center"
          >
            <Flex flex="1">
              <Box fontWeight="700" fontSize="14px" lineHeight="20px" w="50%">
                <Flex flexDirection={{ base: 'column', md: 'row' }}>
                  <Box>
                    <Heading
                      mt="1px"
                      fontStyle="normal"
                      fontWeight="800"
                      fontSize="18px"
                      lineHeight="23px"
                    >
                      {title}
                    </Heading>
                  </Box>
                </Flex>
              </Box>
              <Box fontWeight="700" fontSize="14px" lineHeight="20px" w="50%">
                <Flex
                  flexDirection={{ base: 'column', md: 'row' }}
                  mr={{ base: '30px', md: 'none' }}
                >
                  <Box>
                    <Text
                      mt="3px"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="16px"
                      lineHeight="21px"
                    >
                      {tvl ? (
                        <NumberFormat
                          value={tvl}
                          displayType="text"
                          thousandSeparator
                          prefix="$"
                          renderText={(value) => <>{value}</>}
                        />
                      ) : (
                        'Loading...'
                      )}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </Flex>
            <Flex flex="1">
              <Box fontWeight="700" fontSize="14px" lineHeight="20px" w="50%">
                <Flex
                  flexDirection={{ base: 'column', md: 'row' }}
                  mr={{ base: '30px', md: 'none' }}
                >
                  <Box>
                    <Text
                      mt="3px"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="16px"
                      lineHeight="21px"
                    >
                      {userShare
                        ? `${userShare}% ($${userShareInDollar})`
                        : 'Loading...'}
                    </Text>
                  </Box>
                </Flex>
              </Box>
              <Box fontWeight="700" fontSize="14px" lineHeight="20px" w="50%">
                <Flex
                  flexDirection={{ base: 'column', md: 'row' }}
                  mr={{ base: '30px', md: 'none' }}
                >
                  <Box>
                    <Text
                      mt="3px"
                      fontStyle="normal"
                      fontWeight="700"
                      fontSize="16px"
                      lineHeight="21px"
                    >
                      {APR ? APR : '0%'}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Flex>
          <Flex gap="8px">
            <ButtonBase
              minWidth="130px"
              width="100%"
              isLoading={waiting}
              loadingText="Claiming"
              disabled={waiting}
              background="#8924F4"
              padding="0"
              onClick={(e) => {
                setNetworkModalType(null)
                if (Object.values(polygonChainsIDs).includes(networkId)) {
                  e.preventDefault()
                  setNetworkModalType('eth')
                  setNetworkModal(true)
                }
              }}
            >
              <Link
                href={link}
                isExternal
                fontStyle="normal"
                fontWeight="400"
                fontSize="14px"
                lineHeight="20px"
                className="link_without_borders"
                width="100%"
                padding="10px 5px 10px 17px"
                sx={{
                  display: 'flex',
                  flexBasis: '100%',
                  alignItems: 'center',
                }}
                height="100%"
              >
                {btnTitle}
                <Box sx={{ marginLeft: 'auto' }}>
                  <ArrowOnPurple width={31} height={31} />
                </Box>
              </Link>
            </ButtonBase>

            <CustomToolTip
              arrow
              label="Exciting Update Ahead! Staking is temporarily paused as we prepare to launch a fresh opportunity for you to stake and earn rewards. Stay tuned for details!"
            >
              <div>
                <ButtonBase
                  minWidth="130px"
                  width="100%"
                  fontStyle="normal"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="20px"
                  isLoading={waiting}
                  loadingText="Claiming"
                  disabled
                  onClick={() => {
                    setModalType(modalType)
                    setStakeModal(true)
                  }}
                  padding="10px 5px 10px 17px"
                  sx={{ display: 'flex', flexBasis: '100%' }}
                >
                  Stake
                  <UfoIcon width={31} height={31} />
                </ButtonBase>
              </div>
            </CustomToolTip>
          </Flex>
        </Flex>
        {/* <PoolShare ufoTotalPool={ufoTotalPool} tvl={tvl} userShare={userShare} /> */}
      </BrandedBox>
    )) || (
      <BrandedBox
        flex="1"
        width="100%"
        mt="16px"
        p="30px"
        backgroundColor="main.spaceGrey"
        borderRadius="27px"
        color="main.cream"
        mb="16px"
      >
        <Flex justifyContent="space-between" mb="8px">
          <Flex direction="column">
            <Text fontSize="12" lineHeight="19px" fontWeight="400">
              Pool
            </Text>
            <Text
              fontSize="14px"
              lineHeight="20px"
              fontWeight="700"
              letterSpacing="0.442105px"
            >
              {title}
            </Text>
          </Flex>
          <Flex direction="column" textAlign="right">
            <Text fontSize="12" lineHeight="19px" fontWeight="400">
              APR
            </Text>
            <Text
              fontSize="14px"
              lineHeight="20px"
              fontWeight="700"
              letterSpacing="0.442105px"
            >
              {APR ? APR : '0%'}
            </Text>
          </Flex>
        </Flex>
        <Flex justifyContent="space-between">
          <Flex direction="column">
            <Text fontSize="12" lineHeight="19px" fontWeight="400">
              TVL
            </Text>
            <Text
              fontSize="14px"
              lineHeight="20px"
              fontWeight="700"
              letterSpacing="0.442105px"
            >
              {tvl ? `$${tvl}` : 'Loading...'}
            </Text>
          </Flex>
          <Flex direction="column" textAlign="right">
            <Text fontSize="12" lineHeight="19px" fontWeight="400">
              My Shares
            </Text>
            <Text
              fontSize="14px"
              lineHeight="20px"
              fontWeight="700"
              letterSpacing="0.442105px"
            >
              {userShare ? `${userShare}%` : 'Loading...'}
            </Text>
          </Flex>
        </Flex>
        <Flex justifyContent="space-between" mt="16px">
          <ButtonBase
            minWidth="130px"
            maxWidth="250px"
            width="100%"
            isLoading={waiting}
            loadingText="Claiming"
            disabled={waiting}
            padding="0"
            background="#8924F4"
            mr="32px"
          >
            <Link
              href={link}
              isExternal
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight="20px"
              className="link_without_borders"
              padding="10px 5px 10px 17px"
              sx={{ display: 'flex', flexBasis: '100%', alignItems: 'center' }}
            >
              {btnTitle}
              <Box sx={{ marginLeft: 'auto' }}>
                <ArrowOnPurple width={31} height={31} />
              </Box>
            </Link>
          </ButtonBase>
          <ButtonBase
            minWidth="130px"
            maxWidth="250px"
            width="100%"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight="20px"
            isLoading={waiting}
            loadingText="Claiming"
            disabled={waiting}
            onClick={() => {
              setModalType(modalType)
              setStakeModal(true)
            }}
            padding="10px 5px 10px 17px"
            sx={{ display: 'flex', flexBasis: '100%' }}
          >
            Stake
            <UfoIcon width={31} height={31} />
          </ButtonBase>
        </Flex>
      </BrandedBox>
    )
  )
}
