import { FC } from 'react'

import { ModalPopup } from '@/components'
import {
  MergedLockedDeposit,
  StakesTable,
} from '@/components/pages/Markets/StakesTable'

interface TransactionModalProps {
  isOpen: boolean
  onCloseModal: () => void
  stakes: MergedLockedDeposit[]
}

export const TransactionModal: FC<TransactionModalProps> = ({
  isOpen,
  onCloseModal,
  stakes,
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ModalPopup
        title="TRANSACTION HISTORY"
        isOpen={isOpen}
        onCloseModal={onCloseModal}
      >
        {/* <StakesTable stakes={stakes} /> */}
      </ModalPopup>
    </div>
  )
}
