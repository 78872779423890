import { Link, Box, useMediaQuery, Text } from '@chakra-ui/react'
import { FC } from 'react'

import { ReactComponent as BurgerMenu } from '@/assets/img/burger_menu.svg'
import { ReactComponent as Discrod } from '@/assets/img/social/discord.svg'
import { ReactComponent as Mul } from '@/assets/img/social/mul.svg'
import { ReactComponent as Telegram } from '@/assets/img/social/telegram.svg'
import { ReactComponent as Twitter } from '@/assets/img/social/twitter.svg'
import { ReactComponent as Youtube } from '@/assets/img/social/youtube.svg'

interface Props {
  className?: any
  dark: boolean
  header: boolean
  setLanguage: React.Dispatch<React.SetStateAction<string>>
  onCloseModal: () => void
  openMobileMenu: () => void
}
interface HandleNameChangeInterface {
  target: HTMLInputElement
}
const Social: FC<Props> = ({
  className,
  dark,
  header,
  setLanguage,
  onCloseModal,
  openMobileMenu,
  ...props
}) => {
  const [isLargerThan480] = useMediaQuery('(max-width: 480px)')

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',

        '& > a': {
          marginRight: '6px',
          fontSize: 0,

          '&:last-child': {
            marginRight: 0,
          },
        },
      }}
    >
      {header ? (
        <>
          <Link
            href="https://twitter.com/theufotoken"
            isExternal
            className="link_without_borders"
          >
            <Box
              border={{ base: '1px solid #5DFFCF', sm: 'none' }}
              borderRadius="100%"
              padding="8px"
            >
              <Twitter
                width={isLargerThan480 ? '18' : '27'}
                height={isLargerThan480 ? '14' : '22'}
                fill={dark ? ' #102017' : '#fff'}
              />
            </Box>
          </Link>
          <Link
            href="https://t.me/ufotokenofficial"
            isExternal
            className="link_without_borders"
          >
            <Box
              border={{ base: '1px solid #5DFFCF', sm: 'none' }}
              box-sizing="border-box"
              borderRadius="100%"
              padding="8px"
            >
              <Telegram
                width={isLargerThan480 ? '18' : '24'}
                height={isLargerThan480 ? '15' : '21'}
                fill={dark ? ' #102017' : '#fff'}
              />
            </Box>
          </Link>
          <Link
            href="https://discord.com/invite/Zjdmt62H24"
            isExternal
            className="link_without_borders"
          >
            <Box
              border={{ base: '1px solid #5DFFCF', sm: 'none' }}
              box-sizing="border-box"
              borderRadius="100%"
              padding="8px"
            >
              <Discrod
                width={isLargerThan480 ? '19' : '28'}
                height={isLargerThan480 ? '15' : '22'}
                fill={dark ? ' #102017' : '#fff'}
              />
            </Box>
          </Link>

          <Link href="#">
            <Box
              box-sizing="border-box"
              display={!isLargerThan480 ? 'none' : 'flex'}
            >
              <BurgerMenu
                width="32"
                height="32"
                fill={dark ? ' #102017' : '#fff'}
                onClick={openMobileMenu}
              />
            </Box>
          </Link>
        </>
      ) : (
        <>
          <Link
            href="https://twitter.com/theufotoken"
            isExternal
            className="link_without_borders"
          >
            <Twitter width="27" height="22" fill={dark ? ' #102017' : '#fff'} />
          </Link>
          <Link
            href="https://t.me/ufotokenofficial"
            isExternal
            className="link_without_borders"
          >
            <Telegram
              width="24"
              height="21"
              fill={dark ? ' #102017' : '#fff'}
            />
          </Link>
          <Link
            href="https://discord.com/invite/Zjdmt62H24"
            isExternal
            className="link_without_borders"
          >
            <Discrod width="28" height="22" fill={dark ? ' #102017' : '#fff'} />
          </Link>
          <Link
            href="https://www.youtube.com/channel/UCqIEEqJfp0Mp7-NAs9enJKg"
            isExternal
            className="link_without_borders"
          >
            <Youtube width="29" height="22" fill={dark ? ' #102017' : '#fff'} />
          </Link>
          <Link href="#">
            <Mul width="30" height="27" fill={dark ? ' #102017' : '#fff'} />
          </Link>
        </>
      )}
    </Box>
  )
}

export default Social
