import { Box, BoxProps } from '@chakra-ui/react'
import { FC } from 'react'

export const BrandedBox: FC<BoxProps & { glowing?: boolean }> = ({
  children,
  glowing,
  ...props
}) => {
  if (glowing) {
    return (
      <Box position="relative">
        <Box position="absolute" top="0" w={props.w} h={props.h} />
        <Box {...props}>{children}</Box>
      </Box>
    )
  }

  return <Box {...props}>{children}</Box>
}
