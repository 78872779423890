import {
  Image,
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { FC, ReactNode, useEffect } from 'react'

import cross_btn from '@/assets/img/close.svg'
interface ModalPopupProps {
  title: string
  isOpen: boolean
  onCloseModal: () => void
  size?: string
  isCloseButton?: boolean
  sx?: object
  children?: ReactNode
}
export const ModalPopup: FC<ModalPopupProps> = ({
  title,
  children,
  isOpen,
  onCloseModal,
  size,
  isCloseButton,
  sx,
}) => {
  useEffect(() => {
    if (isOpen) {
      window.scrollTo(0, 0)
    }
  }, [isOpen])

  return (
    // <Box marginLeft="5000px">
    <Modal size={size ? 'lg' : '2xl'} isOpen={isOpen} onClose={onCloseModal}>
      <ModalOverlay bg="rgba(3, 7, 10, 0.7)" opacity="1" />
      <ModalContent
        mt={[0, 100]}
        bg="rgba(0,0,0, 0.73)"
        backdropFilter="blur(20px)"
        {...sx}
      >
        <Box style={isCloseButton ? { padding: '20px 0' } : {}}>
          <ModalHeader
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Heading as="h5" size="lg" color="#fff">
              {title}
            </Heading>
            {!isCloseButton && (
              <Box onClick={onCloseModal} cursor="pointer" mr={4}>
                <Image src={cross_btn} w="50px" />
              </Box>
            )}
          </ModalHeader>

          <ModalBody position="relative">{children}</ModalBody>
        </Box>
      </ModalContent>
    </Modal>
    // </Box>
  )
}
