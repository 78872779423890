import { Box, Flex, Heading, Text, useMediaQuery } from '@chakra-ui/react'
import { sortBy } from 'lodash'
import { useState, useEffect, useRef } from 'react'

import { ClaimPlasmaFunction } from './ClaimPlasmaFunction'
import { TransactionModal } from './TransactionModal'
import { UnstakeFunction } from './UnstakeFuction'
import { UnstakeModal } from './UnstakeModal'
import {
  getMyStakes,
  getStackedFlexiLpDepositOfUser,
  getStackedFlexiUfoDepositOfUser,
} from './service'
import MyStakes from '.././../MyStakes'

import { ReactComponent as UfoIcon } from '@/assets/img/UFO_icon.svg'
import { BrandedBox, OutlineButton } from '@/components'
import Loader from '@/components/Loader'
import ButtonBase from '@/components/button'
import PageSmallBall from '@/components/pageBall/smallBall'
import {
  LockPool,
  MergedLockedDeposit,
  StakesTable,
} from '@/components/pages/Markets/StakesTable'
import { CustomToolTip } from '@/components/tooltip'
import { useGlobalState } from '@/contexts/GlobalContext'
import { getFormatedDate } from '@/utils/getFormatedDate'
import { getLocality } from '@/utils/getLocality'
import { useWallet } from '@/web3/WalletContext'

const fetchDeposits = async (address: any, loader?: any) => {
  loader.current && loader?.current?.continuousStart()
  const deposits = await getMyStakes(address)
  loader.current && loader?.current?.continuousStart()
  const ufoFlexi = await getStackedFlexiUfoDepositOfUser(address)
  const lpFlexi = await getStackedFlexiLpDepositOfUser(address)
  const combinedData = [...deposits]
  if (ufoFlexi) {
    combinedData.push(ufoFlexi)
  }
  if (lpFlexi) {
    combinedData.push(lpFlexi)
  }

  return combinedData
}

const NoStakes = ({ onButtonClick }: { onButtonClick: () => void }) => {
  const { address, signer } = useWallet()
  const [deposits, setDeposits] = useState<any[] | undefined>()
  const [isGreaterThan1230] = useMediaQuery('(min-width: 1230px)')
  const [isGreaterThan990] = useMediaQuery('(min-width: 990px)')
  const [isSmallerThan1100] = useMediaQuery('(max-width: 1110px)')
  const [unstakeDeposit, setUnstakeDeposit] = useState<any | null>(null)
  const stakesLoader = useRef<any | null>(null)

  const loadDeposit = async () => {
    await fetchDeposits(address, stakesLoader).then((data) => {
      setDeposits(data)
    })
  }
  useEffect(() => {
    loadDeposit()
  }, [address])

  return (
    <Box flex="1" pt={20} display="felx">
      <Box
        position="relative"
        id="stake"
        sx={{ borderBottom: '1px solid #111' }}
        mb="16px"
      >
        <Heading variant="h5" as="h3" size="lg" pb={8}>
          My Stakes
        </Heading>
        <Box position="absolute" zIndex="-1" top="30px">
          <PageSmallBall />
        </Box>
      </Box>
      {!deposits && <Loader ref={stakesLoader} />}
      {deposits && !!deposits.length && (
        <>
          <Flex
            fontWeight="400"
            fontSize={isGreaterThan990 && isSmallerThan1100 ? '10px' : '12px'}
            lineHeight="19px"
            width="80%"
            display={{ base: 'none', lg: 'flex' }}
            gap={isGreaterThan990 && isSmallerThan1100 ? '4%' : '5%'}
            m="10px 0 10px 40px"
          >
            <Box
              w={isGreaterThan1230 ? '9%' : '8%'}
              fontStyle="normal"
              fontWeight="700"
              lineHeight="19px"
              color="#102017"
            >
              Pool
            </Box>
            <Box
              w={isGreaterThan1230 ? '10%' : '10%'}
              fontStyle="normal"
              fontWeight="700"
              lineHeight="19px"
              color="#102017"
            >
              Staked Amount
            </Box>
            <Box
              w={isGreaterThan1230 ? '9%' : '9.5%'}
              fontStyle="normal"
              fontWeight="700"
              lineHeight="19px"
              color="#102017"
            >
              Type
            </Box>
            <Box
              w={isGreaterThan1230 ? '6%' : '5%'}
              fontStyle="normal"
              fontWeight="700"
              lineHeight="19px"
              color="#102017"
            >
              APR
            </Box>
            <Flex
              flexDirection="column"
              justify="center"
              alignItems="center"
              textAlign="center"
              w={isGreaterThan1230 ? '15%' : '16%'}
            >
              <Text
                w="100%"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="19px"
                color="#102017"
              >
                Rewards:
              </Text>
              <Text
                w="100%"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="19px"
                color="#102017"
              >
                PLASMA | UFO
              </Text>
            </Flex>
            <Box
              w={isGreaterThan1230 ? '10%' : '10%'}
              fontStyle="normal"
              fontWeight="700"
              lineHeight="19px"
            >
              Unlock Date
            </Box>
          </Flex>
          <Flex
            flexDirection="column"
            gap="20px"
            mt={{ base: '30px', lg: '0' }}
            mb="30px"
          >
            {deposits.length &&
              deposits.map((val, idx) => {
                return (
                  <MyStakes
                    key={idx}
                    isDepositWithdrawable={val.isDepositWithdrawable}
                    pool={val.poolDetails.poolType?.replace('_POOL', '')}
                    amount={
                      val.poolDetails.poolType === 'LP_POOL'
                        ? `${Number(val.amount).toFixed(2)}`
                        : `${Number(val.amount).toFixed(0)}`
                    }
                    type={val.poolDetails.poolLockType}
                    APR={`${Number(val.poolDetails.poolApr).toFixed(2)}%`}
                    UFO={`${Number(val.vestedReward).toFixed(0)}`}
                    PLASMA={`${Number(val.plasmaAvailable).toFixed(0)}`}
                    date={
                      val.poolDetails.poolLockType === 'FLEXI'
                        ? 'Unlocked'
                        : getFormatedDate(val.depositUnlocksAt)
                    }
                    opacityProp="0.5"
                    openModal={() => {
                      if (val.poolDetails.poolLockType === 'LOCKED') {
                        UnstakeFunction(
                          val.poolDetails.lockInWeeks,
                          val.depositNumber,
                          val.poolDetails.poolType?.replace('_POOL', ''),
                          signer,
                          undefined,
                          loadDeposit
                        )
                      } else {
                        setUnstakeDeposit(val)
                      }
                    }}
                    claimPlasma={() => {
                      if (val.depositNumber) {
                        ClaimPlasmaFunction(
                          val.poolDetails.lockInWeeks,
                          val.depositNumber,
                          val.poolDetails.poolType.replace('_POOL', ''),
                          signer,
                          loadDeposit
                        )
                      } else {
                        ClaimPlasmaFunction(
                          val.poolDetails.lockInWeeks,
                          val.depositNumbers,
                          val.poolDetails.poolType.replace('_POOL', ''),
                          signer,
                          loadDeposit
                        )
                      }
                    }}
                  />
                )
              })}
          </Flex>
          {unstakeDeposit && (
            <UnstakeModal
              isOpen={!!unstakeDeposit}
              onCloseModal={() => {
                setUnstakeDeposit(null)
                loadDeposit()
              }}
              data={
                unstakeDeposit
                  ? {
                      lockWeeks: unstakeDeposit?.poolDetails?.lockInWeeks,
                      amount: unstakeDeposit?.amount,
                      depositNumber: unstakeDeposit.depositNumber,
                      depositNumbers: unstakeDeposit.depositNumbers,
                      poolType: unstakeDeposit.poolDetails.poolType,
                    }
                  : null
              }
            />
          )}
        </>
      )}
      {Array.isArray(deposits) && deposits?.length === 0 && (
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Box>
            <Heading as="h4" variant="h6" size="xl">
              Looks like you haven’t staked anything yet.
            </Heading>
            <Box mb={2}>
              <Text>
                Once you stake, you can monitor your investments here.
              </Text>
            </Box>
          </Box>

          <CustomToolTip
            arrow
            // eslint-disable-next-line react/jsx-curly-brace-presence
            label={
              'Exciting Update Ahead! Staking is temporarily paused as we prepare to launch a fresh opportunity for you to stake and earn rewards. Stay tuned for details!'
            }
          >
            <Box sx={{ fontSize: 0, display: 'flex' }}>
              <ButtonBase
                onClick={onButtonClick}
                width="250px"
                padding="10px 10px 10px 15px"
                disabled
              >
                Start staking
                <UfoIcon width={29} height={29} />
              </ButtonBase>
            </Box>
          </CustomToolTip>
        </Box>
      )}
    </Box>
  )
}

export const StakesSummary = ({
  stakeModal,
  setStakeModal,
  setModalType,
}: {
  stakeModal: boolean
  setStakeModal: (toggle: boolean) => void
  setModalType: (type: number) => void
  // setUnstakeData: () => void
}) => {
  const [transationModal, setTransationModal] = useState(false)

  const { ufoLockedDeposits, ufoEthLpLockedDeposits } = useGlobalState()

  const loading =
    ufoLockedDeposits[1].loading || ufoEthLpLockedDeposits[1].loading

  const mergedLockedDeposits: MergedLockedDeposit[] = (() => {
    const ufo = ufoLockedDeposits[0]?.map((d) => ({
      ...d,
      pool: LockPool.UFO,
    }))

    const ufoEthLp = ufoEthLpLockedDeposits[0]?.map((d) => ({
      ...d,
      pool: LockPool.UFO_ETHLP,
    }))
    const lockedDeposits = ufo?.concat(ufoEthLp ?? [])

    return sortBy(lockedDeposits, (item) => item.startDay * -1)
  })()

  if (mergedLockedDeposits.length === 0) {
    return (
      <NoStakes
        onButtonClick={() => {
          setStakeModal(true)
          setModalType(1)
        }}
      />
    )
  }

  const moreThanThreeStakes = mergedLockedDeposits.length > 3

  return (
    <BrandedBox flex="1" px="8" py="6">
      <Box alignItems="center">
        <Heading as="h4" variant="h4">
          MY STAKES
        </Heading>
      </Box>
      {loading ? (
        'Loading'
      ) : (
        <>
          <StakesTable stakes={mergedLockedDeposits.slice(0, 3)} />
          {moreThanThreeStakes && (
            <Flex align="flex-end" justify="flex-end">
              <OutlineButton
                small
                text="VIEW ALL STAKES"
                onClick={() => setTransationModal(true)}
              />
            </Flex>
          )}
        </>
      )}
      <>
        <StakesTable stakes={mergedLockedDeposits.slice(0, 3)} />

        {moreThanThreeStakes && (
          <Flex align="flex-end" justify="flex-end">
            <OutlineButton
              small
              text="VIEW ALL STAKES"
              onClick={() => setTransationModal(true)}
            />
          </Flex>
        )}
      </>
      <TransactionModal
        isOpen={transationModal}
        onCloseModal={() => setTransationModal(false)}
        stakes={mergedLockedDeposits}
      />
    </BrandedBox>
  )
}
