import {
  Box,
  Flex,
  Heading,
  Text,
  Container,
  useMediaQuery,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { SWRConfig } from 'swr'

import './theme/global.scss'
import HowToStakeVideoModal from './components/pages/Markets/HowToStakeVideoModal'
import { getAllValues, getLanguage } from './store/language/action'
import { RootState } from './store/types'

import Routes from '@/Routes'
import { ReactComponent as Bg } from '@/assets/img/bg-t.svg'
import logo from '@/assets/img/logo-acid.svg'
import { ConnectWallet, Menu } from '@/components'
import ButtonBase from '@/components/button'
import Footer from '@/components/layout/footer'
import BurgerMenuComponent from '@/components/molecules/BurgerMenuComponent/index'
import PageBall from '@/components/pageBall'
import { CookieAgreementPopup } from '@/components/popups/cookieAgreementPopup/CookieAgreementPopup'
import LanguageSelectPopUp from '@/components/selectLanguage'

import * as Sentry from '@sentry/react'
import { useCookies } from 'react-cookie'

const STACKING_APP_GA_TRACKING_ID = 'G-C5V9VR81V5'

export const contracts = {
  stakingFactory: '0xE0b16de5178e2149D5BEBd9a753d76a5dC417Aa6',
  depositHelper: '0x3a863e38b448E07431079c7cDa79f4CEeeE3D1AE',
  production: true,
}

// ReactGA.initialize(STACKING_APP_GA_TRACKING_ID, {
//   debug: true,
// })
const STRING_IS_COOKIE_AGREEMENT_ACCEPTED = 'isCookieAgreementAccepted'

const cookieKeysToRemove = ['_ga', '_gid', '_gat']

const PolygonNetworkId = '0x89'

function App() {
  const dispatch = useDispatch()
  const { selectedLanguage } = useSelector((state: RootState) => state.language)
  const [lenguage, steLanguage] = useState('ENG')
  const [isClose, onCloseModal] = useState(false)
  const [isLargerThan480] = useMediaQuery('(max-width: 480px)')
  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false)
  const [showBurgerMenu, setShowBurgerMenu] = useState<boolean>(false)
  const [showCookieAgreement, setShowCookieAgreement] = useState<boolean>(false)
  const [cookie, setCookie, removeCookie] = useCookies()
  const handleOpenVideoModal = () => {
    setIsVideoModalOpen(true)
    setShowBurgerMenu(false)
  }
  const handleCloseVideoModal = () => {
    setIsVideoModalOpen(false)
    setShowBurgerMenu(true)
  }
  const onCookieAgreement = (value: string) => {
    setCookie(STRING_IS_COOKIE_AGREEMENT_ACCEPTED, value)
  }

  const {
    body: { subtitle_description, subtitle, desc, sub_title_footer },
  } = selectedLanguage

  useEffect(() => {
    setShowCookieAgreement(
      !cookie.hasOwnProperty(STRING_IS_COOKIE_AGREEMENT_ACCEPTED)
    )
    if (cookie[STRING_IS_COOKIE_AGREEMENT_ACCEPTED] === 'denied') {
      Object.keys(cookie).forEach(
        (key) => cookieKeysToRemove.includes(key) && removeCookie(key)
      )
    } else if (cookie[STRING_IS_COOKIE_AGREEMENT_ACCEPTED] === 'accepted') {
      ReactGA.initialize(STACKING_APP_GA_TRACKING_ID, {
        debug: true,
      })
    }
  }, [cookie[STRING_IS_COOKIE_AGREEMENT_ACCEPTED]])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  useEffect(() => {
    // let selectedLanguageId = 1
    // LANGUAGE_SELECT.filter((element) => {
    //   if (element.language === lenguage) {
    //     selectedLanguageId = element.id
    //   }
    // })
    // dispatch(getLanguage(selectedLanguageId))
    dispatch(getAllValues())
  }, [lenguage])

  return (
    <SWRConfig
      value={{
        fetcher: fetch,
        shouldRetryOnError: false,
        revalidateOnFocus: false,
      }}
    >
      <BurgerMenuComponent
        isOpen={showBurgerMenu}
        setIsOpen={setShowBurgerMenu}
        openVideoModal={handleOpenVideoModal}
      />
      <Flex direction="column" h="full" color="dark" bg="main.cream">
        <HowToStakeVideoModal
          isOpen={isVideoModalOpen}
          onClose={handleCloseVideoModal}
        />
        {showCookieAgreement && (
          <CookieAgreementPopup onCookieAgreement={onCookieAgreement} />
        )}
        <PageBall />
        <Box w="full" zIndex="10" position="relative">
          <Flex w="full" justify="space-between" p="4">
            <Box>
              <a href="/">
                <img
                  src={logo}
                  alt="super galactic"
                  width={isLargerThan480 ? '90px' : '142px'}
                  height={isLargerThan480 ? '43px' : '68px'}
                />
              </a>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box>
                {isClose && (
                  <LanguageSelectPopUp
                    onCloseModal={() => onCloseModal(false)}
                    steLanguage={steLanguage}
                  />
                )}
                <Menu
                  setLanguage={steLanguage}
                  onCloseModal={() => onCloseModal(true)}
                  openMobileMenu={() => setShowBurgerMenu(true)}
                />
              </Box>
              <ConnectWallet
                openVideoModal={handleOpenVideoModal}
                isWalletVisible={!isVideoModalOpen}
              />
            </Box>
          </Flex>
        </Box>

        <Box
          zIndex="1"
          position="relative"
          paddingTop={['0', '40px']}
          mb={isLargerThan480 ? '2' : '7'}
        >
          <Container maxW="container.xl" marginTop={{ base: '15px', sm: '0' }}>
            <Heading
              variant="h1"
              as="h1"
              fontSize={['46px', '70px']}
              color="main.spaceGrey"
              sx={{ marginBottom: 47 }}
            >
              Staking
            </Heading>

            <Box maxWidth={800} width="100%">
              <Heading
                variant="h6"
                as="h6"
                color="main.spaceGrey"
                fontSize="18px"
                mb={15}
              >
                {subtitle}
              </Heading>

              <Text
                fontSize={{ base: '16px', sm: '22px' }}
                color="main.spaceGrey"
                mb={15}
                sx={{ whiteSpace: 'pre-line' }}
              >
                {subtitle_description}
              </Text>
              <Text
                fontSize={{ base: '16px', sm: '22px' }}
                color="main.spaceGrey"
                mb={15}
                sx={{ whiteSpace: 'pre-line' }}
              >
                {desc}
              </Text>
              <Text
                fontSize={{ base: '16px', sm: '22px' }}
                color="main.spaceGrey"
                sx={{ whiteSpace: 'pre-line' }}
              >
                {sub_title_footer}
              </Text>
            </Box>
            {/* <ButtonBase
              size="sm"
              background=" linear-gradient(180deg, #FA937D 0%, #8924F4 100%);"
              color="white"
              onClick={() => handleNetworkSwitch('polygon')}
            >
              Change Network to Polygon
            </ButtonBase> */}
          </Container>
          <Box
            pos="absolute"
            top="-40px"
            w={['261px', '100px', '50px']}
            h={['239px']}
            right={{ base: '-75px', sm: '440px' }}
          >
            <Bg
              width={!isLargerThan480 ? '493px' : '261px'}
              height={!isLargerThan480 ? '401px' : '239px'}
            />
          </Box>
        </Box>

        <Box
          position="relative"
          sx={{
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Routes />
        </Box>

        <Box zIndex="1" position="relative" paddingTop={10}>
          <Footer />
        </Box>
      </Flex>
    </SWRConfig>
  )
}

export default Sentry.withProfiler(App)
