// Goerli TESTNET
export const UFO_TOKEN_CONTRACT_ADDRESS =
  '0x249e38ea4102d0cf8264d3701f1a0e39c4f2dc3b'
export const UFOETHLP_TOKEN_CONTRACT_ADDRESS =
  '0x97e1fcb93ae7267dbafad23f7b9afaa08264cfd8'

export const UFO_STAKING_CONTRACT_ADDRESS =
  '0xCb29E09d7237ac9DEcBc84C75558808c4CC11DC5'
export const UFOETHLP_STAKING_CONTRACT_ADDRESS =
  '0xcf7Ae8084D4590b3F2C4716eB33611BE3b5E6410'
export const PLASMA_TOKEN_CONTRACT_ADDRESS =
  '0x15b368C09b14148DF5fE4e4Bff80CA270453BBf4'

export const STAKE_RANGES = [
  {
    value: '1',
    label: 'No Lock',
    weight: '1',
    lock: 0,
  },
  {
    value: '2',
    label: '1 Month',
    weight: '1.15',
    lock: 1,
  },
  {
    value: '3',
    label: '10 Months',
    weight: '2',
    lock: 3,
  },
]

export const UNSTAKE_RANGES = [
  {
    value: '1',
    label: '25%',
    weight: '1',
    lock: 0,
  },
  {
    value: '2',
    label: '50%',
    weight: '1.15',
    lock: 1,
  },
  {
    value: '3',
    label: '75%',
    weight: '2',
    lock: 3,
  },
  {
    value: '4',
    label: '100%',
    weight: '2',
    lock: 3,
  },
]

export const LANGUAGE_SELECT = [
  {
    id: 1,
    language: 'English',
  },
  {
    id: 2,
    language: 'Russian',
  },
]

export const FAQ_ENDPOINT = 'https://cms-api.ufogaming.io/api/staking-faqs'
export const LANGUAGE_ENDPOINT =
  'https://dev-admin-v2.ufogaming.io/api/translations/'

const TOASTER_STYLES = {
  minWidth: '100vw',
  height: '72px',
  color: ' #F8F4E4',
  fontFamily: 'Object Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '19px',
}

const TOASTER_DURATION = 10000

const TOASTER_SUCCESS_STYLES = {
  style: {
    background: '#36006E',
  },
  iconTheme: {
    primary: '#D2FF59',
    secondary: '#000000',
  },
}

const TOASTRE_ERROR_STYLES = {
  style: {
    background: '#6e0000',
  },
  iconTheme: {
    primary: '#EB0000',
    secondary: '#ffffff',
  },
}

export const TOASTER_OPTIONS = {
  duration: TOASTER_DURATION,
  style: TOASTER_STYLES,
  success: TOASTER_SUCCESS_STYLES,
  error: TOASTRE_ERROR_STYLES,
}
